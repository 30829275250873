import Vue, { computed, ref } from 'vue'
import type { ApiResponseList } from '/~/types/api'
import { UserMetaDataKey, useUser } from '/~/composables/user'
import { api } from '/~/core'

export interface ThirdPartyUser {
  externalId: string
  firstName: string
  lastName: string
  nickname: string | null
  email: string
  mobile: string
  status: 'activated' | 'deactivated' | 'pending'
  invitationDate: string
  lastLoginDate: string | null
  deactivationDate: string | null
}

export type ThirdPartyUserNew = Pick<
  ThirdPartyUser,
  'firstName' | 'lastName' | 'email' | 'mobile' | 'nickname'
>

export const THIRD_PARTY_USER_ROUTE_PREFIX = '#profile-third-party-user'

const thirdPartyUsers = ref<Array<ThirdPartyUser>>([])
const isLoading = ref(false)

const isThirdPartyEngagementAgreementAccepted = computed(() => {
  const { userMetaData } = useUser()

  return Boolean(
    userMetaData.value[UserMetaDataKey.thirdPartyUsersEngagementAgreement]
      ?.value
  )
})

const fetchThirdPartyUsers = async () => {
  isLoading.value = true

  try {
    const response = (await api.get(
      '/v3/third-party-users'
    )) as ApiResponseList<ThirdPartyUser>

    thirdPartyUsers.value = response.data.items
  } catch (error: any) {
    console.error(error)
    Vue.notify({
      type: 'error',
      text: 'Oops! Something went wrong. Please try again later.',
    })
    thirdPartyUsers.value = []
  } finally {
    isLoading.value = false
  }
}

const inviteThirdPartyUser = async (payload: ThirdPartyUserNew) => {
  isLoading.value = true

  try {
    await api.post<ThirdPartyUser>('/v3/third-party-users', payload, {
      notify: false,
    })
  } finally {
    isLoading.value = false
  }
}

const reinviteThirdPartyUser = async (id: string) => {
  return api.post(
    `/v3/third-party-users/${id}/send-invitation`,
    {},
    {
      notify: false,
    }
  )
}

const deactivateUser = async (user: ThirdPartyUser) => {
  return api.put(
    `/v3/third-party-users/${user.externalId}`,
    {
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user.mobile,
      nickname: user.nickname,
      status: 'deactivated',
    },
    {
      notify: false,
    }
  )
}

const reactivateUser = async (user: ThirdPartyUser) => {
  return api.put(
    `/v3/third-party-users/${user.externalId}`,
    {
      firstName: user.firstName,
      lastName: user.lastName,
      mobile: user.mobile,
      nickname: user.nickname,
      status: 'activated',
    },
    {
      notify: false,
    }
  )
}

const deleteUser = async (id: string) => {
  return api.delete(`/v3/third-party-users/${id}`, {
    notify: false,
  })
}

const updateThirdPartyUser = async (
  id: string,
  payload: Partial<ThirdPartyUserNew>
) => {
  isLoading.value = true

  if (!id) {
    throw new Error('No user user provided')
  }

  try {
    await api.put<ThirdPartyUser>(`/v3/third-party-users/${id}`, payload, {
      notify: false,
    })
  } finally {
    isLoading.value = false
  }
}

async function updateThirdPartyEngagementAgreement(value: boolean) {
  const { updateUserMetadata } = useUser()

  return updateUserMetadata(
    UserMetaDataKey.thirdPartyUsersEngagementAgreement,
    value
  )
}

export function useThirdPartyUsers() {
  return {
    isThirdPartyEngagementAgreementAccepted,
    deactivateUser,
    deleteUser,
    fetchThirdPartyUsers,
    inviteThirdPartyUser,
    isLoading,
    reactivateUser,
    reinviteThirdPartyUser,
    thirdPartyUsers,
    updateThirdPartyUser,
    updateThirdPartyEngagementAgreement,
  }
}
