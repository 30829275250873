import UploadcareDrivder from './driver-uploadcare'

const STATIC_BANNERS = [
  '//ucarecdn.com/186d0a3d-84ff-4063-a2db-4883e1ca2963/',
  '//ucarecdn.com/52ad2449-e388-4fde-b4e0-54823ca459e9/',
  '//ucarecdn.com/06ef24b0-edfb-4724-8cc5-60930861ef71/',
  '//ucarecdn.com/cfc6302f-7449-469c-bd9f-ddb86cfb4b71/',
  '//ucarecdn.com/2b259e05-8b92-4ece-9c7f-cffb78bf33ec/',
  '//ucarecdn.com/a84b7082-40a4-47d5-b111-1858e99c4582/',
  '//ucarecdn.com/d45a59e0-024b-4965-bbd4-757f61ced6ce/',
  '//ucarecdn.com/584b4078-18d6-4ffc-969d-3acd07894874/',
  '//ucarecdn.com/7df03481-cdb7-434c-aa87-20e99f2338d3/',
  '//ucarecdn.com/f3cce18a-861d-4fe8-bb8e-fb5c18752c59/',
  '//ucarecdn.com/7ed143bc-98cf-4de6-9b08-2de05110636a/',
  '//ucarecdn.com/b6fcd02a-e0d6-4a7d-8890-45c3813d7431/',
  '//ucarecdn.com/ff6daaa9-112e-4f8c-989f-b7e9c7d8cd39/',
  '//ucarecdn.com/c7aa8f2c-8f85-4090-9545-c81325148578/',
  '//ucarecdn.com/fd255dea-bcf9-48ec-aed0-ced4c10b54d7/',
]

export default class BannerImageDriver extends UploadcareDrivder {
  /**
   * Sets cdn attribute
   */
  constructor(source: string) {
    super(source)

    let index
    const maxIndex = STATIC_BANNERS.length
    const indexFromSource = Number(
      source.split('.').shift()?.replace(/\D*/g, '')
    )

    if (indexFromSource && indexFromSource < maxIndex) {
      index = indexFromSource
    } else {
      index = Math.floor(Math.random() * maxIndex)
    }

    this.source = STATIC_BANNERS[index]
  }
}
