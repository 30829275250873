import algoliasearch from 'algoliasearch'
import qs from 'query-string'
import Vue, { reactive, ref } from 'vue'
import { getDefaultIndex } from '/~/core/algolia'
import api from '/~/core/api'

const DEFAULT_ALGOLIA_PARAMS = {
  appId: eonx.keys.algolia.appId,
  apiKey: eonx.keys.algolia.searchKey,
}
const HITS_PER_PAGE = 5

const algoliaParams = reactive({
  hitsPerPage: HITS_PER_PAGE,
  queries: [],
  groups: {},
})
const queries = ref([])

const searchResults = ref([])

const isSearchProcessing = ref(false)
const isSearchVisible = ref(false)
const isSearchEmpty = ref(false)
const searchResultCount = ref(0)

function toggleSearchVisibility() {
  isSearchVisible.value = !isSearchVisible.value
}

function clearSearch() {
  searchResults.value = []
  isSearchEmpty.value = false
}

function registerAlgoliaSearch(payload) {
  const config = payload.config ?? {}
  const searchConfig = config.search ?? {}

  if (!searchConfig.enabled) {
    return null
  }

  const algolia = payload.algolia ?? {}
  const order = payload.order ?? 999
  const index = getDefaultIndex(config)
  const filters = searchConfig.filters || config.filters

  if (!index) {
    console.warn('registerAlgoliaSearch', `no index ${searchConfig.group}`)
    return
  }

  if (filters) {
    const localFilters = [filters]
    const payloadFilters = algolia.params?.filters ?? ''

    if (payloadFilters) {
      localFilters.push(payloadFilters)
    }

    if (algolia.params?.filters) {
      algolia.params.filters = localFilters.join(' AND ')
    }
  }

  const group = searchConfig.group || payload.group || payload.label

  algoliaParams.queries.push({
    ...algolia,
    indexName: index,
    group,
  })

  algoliaParams.groups[group] = {
    ...payload,
    group,
    order,
  }
}

function registerSearch(payload) {
  const config = payload.config ?? {}
  const searchConfig = config.search ?? {}

  if (!searchConfig.enabled) {
    return null
  }

  const order = payload.order ?? 999
  const filters = payload.filters || searchConfig.filters || config.filters
  const sort = payload.sort
  const group = searchConfig.group || payload.group || payload.label

  queries.value.push({
    ...payload,
    group,
    sort,
    filters,
    order,
  })
}

function getAlgoliaQueries(searchInput) {
  const queries = {}

  for (const query of algoliaParams.queries) {
    const { config } = algoliaParams.groups[query.group] ?? {}
    const ownSearchKey = config?.algolia_search_key ?? ''
    const ownApplicationID = config?.algolia_app_id ?? ''

    queries[query.group] = queries[query.group] || {
      group: query.group,
    }
    queries[query.group].data = [
      ...(queries[query.group].data || []),
      {
        indexName: query.indexName,
        query: searchInput,
        params: {
          ...query.params,
          hitsPerPage: algoliaParams.hitsPerPage,
        },
      },
    ]

    if (ownSearchKey && ownApplicationID) {
      queries[query.group].algolia_search_key = ownSearchKey
      queries[query.group].algolia_app_id = ownApplicationID
    }
  }

  return queries
}

function algoliaMultipleSearch(queries) {
  const { appId, apiKey } = DEFAULT_ALGOLIA_PARAMS
  const defaultClient = algoliasearch(appId, apiKey)
  const resolver = (client, query) => (resolve) => {
    client
      .multipleQueries(query.data)
      .then((content) => resolve([null, content.results, query.group]))
      .catch((error) => {
        Vue.notify({
          type: 'error',
          text: error.message ?? '',
        })
        resolve([error])
      })
  }

  return Promise.all([
    ...Object.keys(queries).map((key) => {
      let client

      if (queries[key].algolia_search_key && queries[key].algolia_app_id) {
        const { algolia_search_key: searchKey, algolia_app_id: appId } =
          queries[key]

        client = algoliasearch(appId, searchKey)
      } else {
        client = defaultClient
      }

      return new Promise(resolver(client, queries[key]))
    }),
  ])
}

async function multipleAlgoliaSearch(queries) {
  const results = await algoliaMultipleSearch(queries)

  return results
    .reduce((list, [, current = [], group]) => {
      return [
        ...list,
        ...current.map((item) => {
          item.group = group

          return item
        }),
      ]
    }, [])
    .map((result) => {
      const config = algoliaParams.groups[result.group] || {}
      const mapping = config.mapping || ((item) => item)

      return {
        label: config.group,
        data: result.hits.map((item) => {
          return mapping(item)
        }),
        order: config.order,
      }
    })
}

async function search(searchInput) {
  isSearchProcessing.value = true

  const queries = getAlgoliaQueries(searchInput)

  try {
    const [data, queriesData] = await Promise.all([
      multipleAlgoliaSearch(queries),
      getQueriesSearchResult(searchInput),
    ])
    const combinedData = [...data, ...queriesData]
    const groupedData = []

    for (const item of combinedData) {
      const label = item.label
      const index = groupedData.findIndex((item) => item.label === label)

      if (index === -1) {
        groupedData.push({ label, data: item.data, order: item.order })
      } else {
        groupedData[index].data = [...groupedData[index].data, ...item.data]
      }
    }

    searchResults.value = groupedData.sort((a, b) =>
      a.order < b.order ? -1 : a.order > b.order ? 1 : 0
    )
  } catch (error) {
    console.error(error)
  } finally {
    isSearchProcessing.value = false
    searchResultCount.value = searchResults.value.reduce(
      (count, item) => count + item.data.length,
      0
    )
    isSearchEmpty.value = searchResultCount.value === 0
  }
}

async function getQueriesSearchResult(searchInput) {
  const promises = []

  for (const query of queries.value) {
    const queryString = [
      qs.stringify({
        page: 1,
        perPage: HITS_PER_PAGE,
        [query.searchField]: searchInput,
        ...query.filters,
      }),
      query.sort,
    ]
      .filter((i) => Boolean(i))
      .join('&')

    promises.push(
      api.get(`${query.url}?${queryString}`).then((result) => ({
        data: result.data.items.map((item) => query.mapping?.(item) ?? item),
        label: query.group,
        order: query.order,
      }))
    )
  }

  return Promise.all(promises)
}

export function useSearch() {
  return {
    searchResults,
    searchResultCount,
    isSearchVisible,
    isSearchEmpty,
    isSearchProcessing,

    search,
    registerSearch,
    registerAlgoliaSearch,
    toggleSearchVisibility,
    clearSearch,
  }
}
