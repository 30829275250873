import { useLocalization } from '/~/composables/localization'

const localization = useLocalization()

export function ensureNumber(
  value: number | string | null | undefined,
  emptyValue?: number | string
): number {
  if (typeof value === 'number') {
    return value
  }

  if (value && typeof value === 'string') {
    return parseFloat(value.replace(/[^\d.]/g, '')) || 0
  }

  return parseFloat(`${emptyValue ?? value}`) || 0
}

export function formatNumber(
  value: string | number | null | undefined,
  emptyValue?: string | number
): string {
  const parsedValue = ensureNumber(value, emptyValue)

  return localization.formatNumber(parsedValue)
}

export { formatPhone } from './string'
export { formatPhone as phone } from './string'

const getCustomRounderFunctionFromNumber = function (value: number) {
  const lastDigitOfDecimal = getDigitFromDecimalNumber(value, 2)

  if (lastDigitOfDecimal < 5) {
    return Math.floor
  } else {
    return Math.ceil
  }
}

const getDigitFromDecimalNumber = function (
  num: number,
  startingFractionDigits: 2
) {
  const numParts = num.toString().split('.')

  if (numParts.length === 2) {
    const decimalPartInString = numParts[1]

    return Number(
      decimalPartInString.substring(
        startingFractionDigits,
        startingFractionDigits + 1
      )
    )
  }

  return 0
}

export function roundFigure(value = 0) {
  return processFigure(value, getCustomRounderFunctionFromNumber(value))
}

export function floorFigure(value = 0) {
  return processFigure(value, Math.floor)
}

export function ceilFigure(value = 0) {
  return processFigure(value, Math.ceil)
}

function processFigure(value = 0, func: (v: number) => typeof v) {
  return func(Number((value * 100).toFixed(2))) / 100
}
