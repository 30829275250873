import { getCurrentInstance, computed } from 'vue'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { useAuth } from '/~/composables/auth'
import { useSearch } from '/~/composables/search'
import { useUI } from '/~/composables/ui'
import { useUserEvents } from '/~/composables/user'
import actions from '/~/configurator/actions.json'

type InputEmits = {
  (event: 'collapse'): void
}

export function useAction(props: any, emit?: InputEmits) {
  const instance = computed(() => getCurrentInstance())
  const attrs = computed(() => instance.value?.proxy.$attrs)

  type Action = {
    label: string
    action?: string
    modal?: string
    hash?: string
  }

  type ActionKey = keyof typeof actions

  const { toggleSearchVisibility } = useSearch()
  const {
    toggleCartMenu,
    toggleEwalletMenu,
    toggleProfileMenu,
    toggleTierModal,
  } = useUI()
  const { logout } = useAuth()
  const { bannerClicked } = useUserEvents()

  function collapse() {
    if (emit) emit('collapse')
  }

  const isLink = computed(() => {
    return (
      /link|page/.test(props.type) && !props.clickOnly && !props.isRestricted
    )
  })

  const bindings = computed(() => {
    delete attrs.value?.queryString
    const { type, value, params, query } = props

    if (props.clickOnly || props.isRestricted) {
      return {}
    }

    if (type === 'page') {
      if (typeof value === 'string') {
        const to: any = {
          params,
          query,
        }

        if (value.startsWith('/#')) {
          to.hash = value.substr(1)
        } else {
          to.name = value
        }

        return {
          ...attrs.value,
          to,
        }
      } else {
        return {
          ...attrs.value,
          to: value,
        }
      }
    }

    if (type === 'link') {
      return {
        ...attrs.value,
        href: value,
      }
    }

    return {}
  })

  function handleAction() {
    if (props.isRestricted) {
      return
    }

    const { type, value, origin, params, query, label } = props

    if (isLink.value && type === 'page' && !emit) {
      emitter.emit('router:push', { name: value, query, params })
    }

    if (origin === 'banner') {
      bannerClicked({
        type,
        value,
        params,
        query,
        label,
      })
    }

    if (type === 'action') {
      const actionMeta = actions[value as ActionKey] as Action

      if (actionMeta) {
        const { action, modal: modalName, hash } = actionMeta

        switch (action) {
          case 'toggleSearchVisibility':
            toggleSearchVisibility()
            break
          case 'toggleCartMenu':
            toggleCartMenu()
            break
          case 'toggleEwalletMenu':
            toggleEwalletMenu()
            break
          case 'toggleProfileMenu':
            toggleProfileMenu()
            break
          case 'toggleTierModal':
            toggleTierModal()
            break
          case 'logout':
            logout()
            break
          case 'collapse':
            collapse()
            break
        }
        if (modalName) modal.show(modalName)
        if (hash) {
          toggleEwalletMenu(false)
          setTimeout(() => {
            emitter.emit('router:replace', { hash })
          }, 500)
        }
      }
    }

    if (type === 'video') {
      modal.show('embedded-video-player', {
        props: { source: value },
      })
    }
  }

  return {
    isLink,
    bindings,
    handleAction,
  }
}
