import { JwtStrategy } from '/~/core/Jwt/JwtStrategy'
import { api } from '/~/core'

export class EnrolmentJwtStrategy extends JwtStrategy implements IJwtStrategy {
  promise?: null | Promise<any>
  triggerPoint = 'enrolment'
  channel: string

  constructor(channel: string) {
    super()
    this.channel = channel
  }

  async generateToken() {
    if (this.promise) {
      await this.promise
    }

    this.promise = api.post('/v3/otp/jwt', {
      triggerPoint: this.triggerPoint,
      deliveryChannel: this.channel,
    })

    try {
      const response = await this.promise

      return response.data?.token as string
    } catch (error) {
      console.error('Unable to generate JWT token', error)
      throw error
    } finally {
      this.promise = null
    }
  }
}
