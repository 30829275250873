import { computed, ref } from 'vue'
import {
  useBatchOrder,
  useBatchOrderStatusHandler,
} from '/~/composables/batch-order'
import { BatchOrderNewPayees } from './core/BatchOrderNewPayees'

const loading = ref(false)

export function useBatchOrderNewPayees() {
  const { batchOrder, syncBatchOrderPayees } = useBatchOrder()
  const { batchOrderStatusHandler } = useBatchOrderStatusHandler()

  const missingPayees = new BatchOrderNewPayees({
    url: `/v3/batch-orders/${batchOrder.number}/missing-payees`,
    perPage: '10',
  })

  const allCreated = computed(
    () =>
      missingPayees.payeeList.createdLocalPayees.length ===
      missingPayees.pagination.totalItems
  )

  const payeesRemaining = computed(() =>
    missingPayees.pagination.totalItems > 0
      ? missingPayees.pagination.totalItems -
        missingPayees.payeeList.createdLocalPayees.length
      : 0
  )

  async function onContinue() {
    try {
      loading.value = true
      await syncBatchOrderPayees()
      batchOrderStatusHandler.startCheckingLoop()
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  return {
    missingPayees,
    allCreated,
    payeesRemaining,
    onContinue,
    loading,
  }
}
