export function cardCode(card: { brand?: string }) {
  const brand = (card?.brand ?? '').toLowerCase()

  switch (brand) {
    case 'american express':
      return 'amex'
    case 'visaelectron':
      return 'visa'
    default:
      return brand
  }
}
