<script>
export default {
  name: 'base-loader',
  props: {
    size: {
      type: String,
      default: 'lg',
      validator: (v) => !v || /xxs|xs|sm|md|lg|xl/.test(v),
    },
    fullscreen: {
      type: Boolean,
      default: false,
    },
    fullwidth: {
      type: Boolean,
      default: false,
    },
    borderColor: {
      type: String,
      default: 'rgba(0, 0, 0, 0.12)',
    },
    color: {
      type: String,
      default: 'var(--color-primary, black)',
    },
  },
  computed: {
    loaderStyle() {
      return {
        borderColor: this.borderColor,
        borderTopColor: this.color,
      }
    },
  },
}
</script>

<template>
  <div
    data-testid="base-loader"
    class="inline-flex shrink-0 items-center justify-center"
    :class="[
      fullscreen && 'fixed inset-0 h-full w-full',
      fullwidth && 'w-full',
    ]"
  >
    <div
      class="relative shrink-0 animate-spin rounded-full border-solid"
      :class="[
        size === 'xxs' && 'h-[15px] w-[15px] border-2',
        size === 'xs' && 'h-5 w-5 border-2',
        size === 'sm' && 'h-6 w-6 border-3',
        size === 'md' && 'h-7 w-7 border-3',
        size === 'lg' && 'h-[35px] w-[35px] border-3',
        size === 'xl' && 'h-12 w-12 border-3',
        size === '2xl' && 'h-20 w-20 border-8',
      ]"
      :style="loaderStyle"
    />
  </div>
</template>
