export class BatchOrderTransactionsTab {
  name: string
  status: string
  isActive = false
  statusItemCount = 0
  queryStatuses: string[]

  constructor(name: string, status: string, queryStatuses: string[]) {
    this.name = name
    this.status = status
    this.queryStatuses = queryStatuses
  }

  get label() {
    // Temporary remove statusItemCount SIR-1678
    // return `${this.name} (${this.statusItemCount})`
    return this.name
  }

  setActive(value: boolean) {
    this.isActive = value
  }

  addStatusItemCount(value: number) {
    this.statusItemCount = this.statusItemCount + value
  }
}
