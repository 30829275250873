import Vue, { reactive, ref, computed, nextTick } from 'vue'
import emitter from '/~/core/emitter'
import { sleep } from '/~/utils/timer'
import { useCms } from '/~/composables/cms'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'

const isHeadroomActive = ref(true)
const isEwalletMenuVisible = ref(false)
const isProfileMenuVisible = ref(false)
const isCartMenuVisible = ref(false)
const isQuickBuyMenuVisible = ref(false)
const keyboardAccessAreas = reactive({})
const keyboardAccessAreaQueue = ref(Promise.resolve())
const initialActiveElement = ref(null)
const headerHeight = ref(0)

const isIOS = computed(() =>
  Boolean(navigator.userAgent.match(/(iPad|iPhone|iPod)/i))
)
const isStandalone = computed(() => Boolean(window?.navigator?.standalone))
const isRequiredAsteriskVisible = computed(() => {
  const { isBupaTemplate } = useProvider()

  return !isBupaTemplate.value
})

const uiReady = computed(() => {
  const { extensions } = useExtensions()

  return extensions.loaded
})

const isInertEnabled = computed(() => {
  const { cmsConfig } = useCms()

  return cmsConfig.value['a11y-inert-enabled'] ?? false
})

const topMenu = computed(() => {
  const { getMenuItems } = useCms()

  return getMenuItems('top')
})

const leftMenu = computed(() => {
  const { getMenuItems } = useCms()

  return getMenuItems('left')
})

const leftMenuMobile = computed(() => {
  const { getMenuItems } = useCms()

  return getMenuItems('left-mobile')
})

const footerMenu = computed(() => {
  const { getMenuItems } = useCms()

  return getMenuItems('footer')
})

const settingsMenu = computed(() => {
  const { getMenuItems } = useCms()

  return getMenuItems('settings')
})

const isAnyMenuVisible = computed(() => {
  return (
    isEwalletMenuVisible.value ||
    isProfileMenuVisible.value ||
    isCartMenuVisible.value ||
    isQuickBuyMenuVisible.value
  )
})

const drawerModalsTargetName = computed(() => {
  if (isCartMenuVisible.value) {
    return 'cart-menu-modal'
  } else if (isQuickBuyMenuVisible.value) {
    return 'quick-buy-menu-modal'
  }

  return 'menu-modal'
})

function toggleMenu(isMenuVisible, visible) {
  if (typeof visible === 'boolean') {
    isMenuVisible.value = visible
  } else {
    isMenuVisible.value = !isMenuVisible.value
  }
}

function hideEwalletMenu() {
  toggleMenu(isEwalletMenuVisible, false)
  toggleMenu(isProfileMenuVisible, false)
}

function toggleEwalletMenu(visible) {
  toggleMenu(isEwalletMenuVisible, visible)
}

function toggleProfileMenu(visible) {
  toggleMenu(isProfileMenuVisible, visible)
}

function toggleCartMenu(visible) {
  toggleMenu(isCartMenuVisible, visible)
}

function toggleQuickBuyMenu(visible) {
  toggleMenu(isQuickBuyMenuVisible, visible)
}

function toggleTierModal() {
  emitter.emit('ewallet:toggle-tier-modal')
}

async function lockKeyboardAccessArea(
  params = {
    name: null,
    rootElement: null,
    activeElement: null,
    focusElement: null,
    delay: 0,
  }
) {
  const { isA11yEnabled } = useProvider()

  if (!isA11yEnabled.value) {
    return
  }

  if (Object.keys(keyboardAccessAreas).length > 0) {
    keyboardAccessAreaQueue.value = keyboardAccessAreaQueue.value.then(() => {
      const lastKeyboardAccessAreaId = Object.keys(keyboardAccessAreas).pop()

      if (lastKeyboardAccessAreaId) {
        return _unlockKeyboardAccessArea(lastKeyboardAccessAreaId, true)
      }
    })
  }

  keyboardAccessAreaQueue.value = keyboardAccessAreaQueue.value.then(() => {
    return _lockKeyboardAccessArea(params)
  })

  return keyboardAccessAreaQueue.value
}

async function _lockKeyboardAccessArea(params, temporary = false) {
  const currentActiveElement = document.activeElement

  if (!temporary) {
    await sleep(params.delay)
  }

  if (!params.rootElement || params.rootElement.style.display === 'none') {
    return
  }

  const keyboardAccessArea = {
    id: params.id,
    rootElement: params.rootElement,
    parentElement: params.parentElement ?? params.rootElement?.parentNode,
    previousActiveElement: params.previousActiveElement,
    focusElement: params.focusElement,
    firstTab: null,
    lastTab: null,
    delay: params.delay,
    checkUnlockListener: () => checkUnlockKeyboardAccessArea(params.id),
    checkTabListener: () => checkTabKeyboardAccessArea(params.id),
  }

  if (
    !keyboardAccessArea.previousActiveElement ||
    keyboardAccessArea.previousActiveElement.tagName.toLowerCase() === 'body'
  ) {
    keyboardAccessArea.previousActiveElement =
      Object.keys(keyboardAccessAreas).length > 0
        ? currentActiveElement
        : initialActiveElement.value ?? currentActiveElement
  }

  if (Object.keys(keyboardAccessAreas).length === 0) {
    initialActiveElement.value = keyboardAccessArea.previousActiveElement
  }

  Vue.set(keyboardAccessAreas, params.id, keyboardAccessArea)

  setKeyboardAccessAreaTabs(keyboardAccessArea)

  keyboardAccessArea.rootElement.isLockedKeyboardAccessArea = true
  if (isInertEnabled.value) {
    toggleInertForKeyboardAccessArea(params.id, true)
  }

  keyboardAccessArea.rootElement.addEventListener(
    'keydown',
    keyboardAccessArea.checkUnlockListener
  )
  keyboardAccessArea.rootElement.addEventListener(
    'keydown',
    keyboardAccessArea.checkTabListener
  )

  if (keyboardAccessArea.focusElement) {
    keyboardAccessArea.focusElement.focus()
  } else {
    keyboardAccessArea.firstTab?.focus()
  }
}

async function unlockKeyboardAccessArea(areaId) {
  const { isA11yEnabled } = useProvider()

  if (!isA11yEnabled.value) {
    return
  }

  const id = areaId ?? Object.keys(keyboardAccessAreas).pop()

  if (!keyboardAccessAreas[id]) {
    return
  }

  const previousActiveElement = keyboardAccessAreas[id]?.previousActiveElement
  const keyboardAccessAreaIds = Object.keys(keyboardAccessAreas)
  const currentKeyboardAccessAreaIndex = keyboardAccessAreaIds.findIndex(
    (keyboardAccessAreaId) => {
      return keyboardAccessAreaId === id
    }
  )
  const previousKeyboardAccessAreaId =
    keyboardAccessAreaIds[currentKeyboardAccessAreaIndex - 1]
  const nextKeyboardAccessAreaId =
    keyboardAccessAreaIds[currentKeyboardAccessAreaIndex + 1]

  keyboardAccessAreaQueue.value = keyboardAccessAreaQueue.value.then(() => {
    return _unlockKeyboardAccessArea(id)
  })

  keyboardAccessAreaQueue.value = keyboardAccessAreaQueue.value.then(() => {
    if (nextKeyboardAccessAreaId > -1 && previousActiveElement) {
      keyboardAccessAreas[nextKeyboardAccessAreaId].previousActiveElement =
        previousActiveElement
    }

    if (previousKeyboardAccessAreaId > -1) {
      if (previousActiveElement) {
        keyboardAccessAreas[previousKeyboardAccessAreaId].focusElement =
          previousActiveElement
      }

      return _lockKeyboardAccessArea(
        keyboardAccessAreas[previousKeyboardAccessAreaId],
        true
      )
    } else {
      initialActiveElement.value = null
    }
  })

  return keyboardAccessAreaQueue.value
}

async function _unlockKeyboardAccessArea(areaId, temporary = false) {
  const id = areaId ?? Object.keys(keyboardAccessAreas).pop()

  const keyboardAccessArea = keyboardAccessAreas[id]
  const keyboardAccessAreaIds = Object.keys(keyboardAccessAreas)
  const currentKeyboardAccessAreaIndex = keyboardAccessAreaIds.findIndex(
    (keyboardAccessAreaId) => {
      return keyboardAccessAreaId === id
    }
  )
  const previousKeyboardAccessAreaId =
    keyboardAccessAreaIds[currentKeyboardAccessAreaIndex - 1]
  const nextKeyboardAccessAreaId =
    keyboardAccessAreaIds[currentKeyboardAccessAreaIndex - 1]

  if (!keyboardAccessArea?.rootElement) {
    delete keyboardAccessAreas[id]
    return
  }

  if (isInertEnabled.value) {
    toggleInertForKeyboardAccessArea(id, false)
  }

  keyboardAccessArea.rootElement.isLockedKeyboardAccessArea = false

  if (!temporary) {
    await new Promise((resolve) =>
      setTimeout(resolve, keyboardAccessArea.delay)
    )
  }

  keyboardAccessArea.rootElement.removeEventListener(
    'keydown',
    keyboardAccessArea.checkUnlockListener
  )
  keyboardAccessArea.rootElement.removeEventListener(
    'keydown',
    keyboardAccessArea.checkTabListener
  )

  if (
    keyboardAccessArea.previousActiveElement &&
    keyboardAccessArea.previousActiveElement.tagName.toLowerCase() !== 'body' &&
    !keyboardAccessArea.previousActiveElement.closest(
      '[style*="display:none"], [style*="display: none"]'
    )
  ) {
    keyboardAccessArea.previousActiveElement.tabIndex = -1
    keyboardAccessArea.previousActiveElement.focus()
    keyboardAccessArea.previousActiveElement.tabIndex = null
  } else {
    keyboardAccessAreas[previousKeyboardAccessAreaId]?.firstTab?.focus()
  }

  if (!temporary) {
    if (currentKeyboardAccessAreaIndex === 0 && nextKeyboardAccessAreaId) {
      keyboardAccessAreas[nextKeyboardAccessAreaId].previousActiveElement =
        initialActiveElement
    }

    delete keyboardAccessAreas[id]
  }
}

function toggleInertForKeyboardAccessArea(id, inert) {
  const keyboardAccessArea = keyboardAccessAreas[id]

  if (!keyboardAccessArea) {
    return
  }

  let parent = keyboardAccessArea.parentElement

  while (parent) {
    const childrens = Array.from(parent.children).filter((children) => {
      return children !== keyboardAccessArea.rootElement
    })

    for (const element of childrens) {
      if (element.hasAttribute('data-skip-inert-toggling')) {
        continue
      }

      let containKeyboardAccessArea = false

      for (const id in keyboardAccessAreas) {
        if (!keyboardAccessAreas[id].rootElement.isLockedKeyboardAccessArea) {
          continue
        }

        if (
          element === keyboardAccessAreas[id].rootElement ||
          element.contains(keyboardAccessAreas[id].rootElement)
        ) {
          containKeyboardAccessArea = true
          break
        }
      }

      element.inert = inert ? !containKeyboardAccessArea : false
    }

    parent = parent.parentNode
  }
}

function setKeyboardAccessAreaTabs(keyboardAccessArea) {
  const tabElements = Array.from(
    keyboardAccessArea.rootElement.querySelectorAll(
      'a[href], button, input, [role="button"], [tabindex], .base-select__item'
    )
  ).filter((element) => {
    return !element.closest(
      '[style*="display:none"], [style*="display: none"], [disabled]'
    )
  })

  keyboardAccessArea.firstTab = tabElements[0]
  keyboardAccessArea.lastTab = tabElements[tabElements.length - 1]
}

async function checkUnlockKeyboardAccessArea(id) {
  if (event.keyCode === 27) {
    // ESC
    event.stopPropagation()

    return unlockKeyboardAccessArea(id)
  }
}

function checkTabKeyboardAccessArea(id) {
  if (event.key !== 'Tab' && event.keyCode !== 9) {
    return
  }

  const keyboardAccessArea = keyboardAccessAreas[id]

  setKeyboardAccessAreaTabs(keyboardAccessArea)

  if (event.shiftKey) {
    if (document.activeElement === keyboardAccessArea.firstTab) {
      keyboardAccessArea.lastTab.focus()
      event.preventDefault()
    }
  } else if (document.activeElement === keyboardAccessArea.lastTab) {
    keyboardAccessArea.firstTab.focus()
    event.preventDefault()
  }
}

export function useUI() {
  const titleRef = ref(null)

  function titleFocus() {
    titleRef.value?.focus()
  }

  nextTick(() => {
    titleFocus()
  })

  return {
    uiReady,
    isHeadroomActive,
    isEwalletMenuVisible,
    isProfileMenuVisible,
    isCartMenuVisible,
    isQuickBuyMenuVisible,
    isAnyMenuVisible,
    isIOS,
    isStandalone,
    isRequiredAsteriskVisible,

    topMenu,
    leftMenu,
    leftMenuMobile,
    footerMenu,
    settingsMenu,
    drawerModalsTargetName,

    hideEwalletMenu,
    toggleCartMenu,
    toggleEwalletMenu,
    toggleProfileMenu,
    toggleQuickBuyMenu,
    toggleTierModal,

    lockKeyboardAccessArea,
    unlockKeyboardAccessArea,

    titleRef,
    titleFocus,
    headerHeight,
  }
}
