import { Component } from 'vue'

export let isMissingImport = false

type PreloadActionCallback = () => void
let defaultLoadingComponent: Component
let defaultErrorComponent: Component
let defaultErrorModalComponent: Component
let defaultPreloadAction: PreloadActionCallback

const MissingImportMessages = [
  'Importing a module script failed', // safari
  'Failed to fetch dynamically imported module', // edge & chrome
]

function handleResolveError(e: Error) {
  if (!e || !e.message) return

  isMissingImport =
    navigator.onLine &&
    MissingImportMessages.some((importMessage) =>
      e.message.includes(importMessage)
    )
}

// TODO: set concrete type to source
async function loadSource(source: any) {
  try {
    return await source
  } catch (e: any) {
    handleResolveError(e)
    throw e
  }
}

// TODO: add concrete type to AsynComponent
export function lazy(
  AsyncComponent: any,
  {
    LoadingComponent = defaultLoadingComponent,
    ErrorComponent = defaultErrorComponent,
    preloadAction = defaultPreloadAction,
  } = {}
) {
  const component = async () => {
    await preloadAction?.()

    if (AsyncComponent instanceof Promise) {
      return loadSource(AsyncComponent)
    }

    if (AsyncComponent instanceof Function) {
      return loadSource(AsyncComponent())
    }

    return AsyncComponent
  }

  const AsyncHandler = () => {
    return {
      component: component(),
      // A component to use while the component is loading.
      loading: LoadingComponent,
      // Delay before showing the loading component.
      // Default: 200 (milliseconds).
      delay: 0,
      // A fallback component in case the timeout is exceeded
      // when loading the component.
      error: ErrorComponent,
      // Time before giving up trying to load the component.
      // Default: Infinity (milliseconds).
      timeout: 15000,
    }
  }

  // TODO: add types to h, { data, chilren } structure
  return Promise.resolve({
    functional: true,
    render: (h: any, { data, children }: any) =>
      h(AsyncHandler, data, children),
  })
}

// TODO: add type to modal
export function lazyModal(modal: any) {
  let _modal

  if (!`${modal}`.match('lazy')) {
    _modal = () =>
      lazy(modal, {
        ErrorComponent: defaultErrorModalComponent,
      })
  } else {
    _modal = modal
  }

  return _modal
}

// TODO: add type to bottomsheet
export function lazyBottomSheet(bottomSheet: any) {
  let _bottomSheet

  if (!`${bottomSheet}`.match('lazy')) {
    _bottomSheet = () => lazy(bottomSheet)
  } else {
    _bottomSheet = bottomSheet
  }

  return _bottomSheet
}

// TODO: add concrete type to route
export function lazyRoute(route: any) {
  let component

  if (!`${route.component}`.match('lazy')) {
    component = () =>
      lazy(route.component, {
        ErrorComponent: defaultErrorComponent,
        preloadAction: defaultPreloadAction,
      })
  } else {
    component = route.component
  }

  return {
    ...route,
    component,
    children: route.children?.map(lazyRoute),
  }
}

// TODO: add type to route
export function lazyEwalletRoute(route: any) {
  return () => lazy(route)
}

interface LazyOptions {
  preloadAction: PreloadActionCallback
  errorComponent: Component
  errorModalComponent: Component
  loadingComponent: Component
}

export function configure(lazyOptions: LazyOptions) {
  defaultErrorComponent = lazyOptions.errorComponent
  defaultErrorModalComponent = lazyOptions.errorModalComponent
  defaultPreloadAction = lazyOptions.preloadAction
  defaultLoadingComponent = lazyOptions.loadingComponent
}
