export default {
  // Payment methods
  'nab-verification': () =>
    import('./payment-methods/nab-verification/modals/nab-verification.bs.vue'),
  'nab-verification-cancel': () =>
    import(
      './payment-methods/nab-verification/modals/nab-verification-cancel.bs.vue'
    ),
  'cvv-code': () => import('/~/modals/payment-methods/card/cvv-code.bs.vue'),
  'payments-split': () => import('./payments/split/payments-split.bs.vue'),
  'payments-split-v2': () =>
    import('./payments/split/payments-split.bs.v2.vue'),
  'confirmation-modal': () => import('./ewallet/confirmation-modal.bs.vue'),
  'confirmation-modal-v2': () =>
    import('./ewallet/confirmation-modal.bs.v2.vue'),
  'bank-account-warning': () =>
    import('./payment-methods/info/bank-account-warning.bs.vue'),
  'bank-account-pad': () =>
    import('./payment-methods/info/bank-account-pad.bs.vue'),
  'method-verify': () => import('./verify/method-verify.vue'),

  // epurchases
  'epurchase-unlock-v2': () => import('./purchase/puchase-unlock.bs.v2.vue'),
  'epurchase-unlock-v3': () => import('./purchase/puchase-unlock.bs.v3.vue'),

  // profile
  'profile-password-confirmation': () =>
    import('./profile/password-confirmation.bs.vue'),
  'terms-conditions': () =>
    import('./terms-conditions/terms-conditions.bs.vue'),

  // withdraw
  'withdraw-options': () => import('./withdraw/withdraw-options.bs.vue'),

  confirm: () => import('./confirm/confirm.bs.vue'),
  avatar: () => import('/~/components/drawer/modals/drawer-avatar-modal.vue'),

  // Verification center
  'verification-warning': () =>
    import('/~/extensions/otp/components/verification-warning.bs.vue'),
}
