export function formatBusinessNumber(value: string) {
  const matches = String(value).match(/(\d{2})(\d{3})(\d{3})(\d{3}){1}/)

  if (matches) {
    matches.splice(0, 1)

    return matches.join(' ')
  } else {
    return value
  }
}
