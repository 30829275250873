import BatchOrderTransaction from './BatchOrderTransaction'

export class BatchOrderTransactionList {
  list: BatchOrderTransaction[] = []

  reset() {
    this.list = []
  }

  setItems(list: BatchOrderTransaction[]) {
    this.list = list
  }

  addItems(list: BatchOrderTransaction[]) {
    this.list = [...this.list, ...list]
  }
}
