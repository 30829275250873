<script>
import { nextTick } from 'vue'

const cache = {
  /* empty placeholder */
  dot: '<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24"><path d="M24 24H0V0h24v24z" fill="none"/><circle cx="12" cy="12" r="8"/></svg>',
}

export default {
  name: 'base-icon',
  props: {
    /*
     * Render as an <img> tag
     */
    src: {
      type: String,
      default: '',
    },
    /*
     * Render inside DOM
     */
    svg: {
      type: String,
      default: '',
    },
    size: {
      type: [String, Number],
      default: '',
      validator: (v) =>
        !v ||
        typeof v === 'number' ||
        /xxs|xs|sm|sme|md|lg|xl|2xl|3xl|4xl|5xl|6xl|auto|unset/.test(v),
    },
    color: {
      type: String,
      default: '',
    },
    alt: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: '',
      validator: (v) => !v || /icons|image/.test(v),
    },
    ariaHidden: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      html: '<i></i>',
    }
  },
  computed: {
    path() {
      return `/icons/${this.svg || this.src}.svg`
    },
  },
  watch: {
    svg() {
      this.loadIcon()
    },
  },
  created() {
    if (this.svg) {
      this.loadIcon()
    }
  },
  methods: {
    async loadIcon() {
      try {
        let html = await this.getSvgContent()

        if (!html || !/^<svg/.test(html)) {
          html = this.alt
            ? `<span class="flex justify-center h-full items-center" style="text-shadow: 0 1px 1px #333;">${this.alt}</span>`
            : cache.dot
        }

        this.html = html
          .replace(/id="(.*?)"/g, `id="$1-${this._uid}"`)
          .replace(/url\(#(.*?)\)/g, `url(#$1-${this._uid})"`)
          .replace(
            /aria-labelledby="(.*?)"/g,
            'aria-labelledby="$1-' + this._uid + '"'
          )

        await nextTick()

        const el = this.$el.querySelector('svg')

        if (el) {
          let tailwindClasses = 'block h-full max-w-inherit max-h-inherit'

          if (this.size !== 'unset') {
            tailwindClasses += ' w-full'
          }

          tailwindClasses.split(' ').forEach((item) => el.classList.add(item))
          if (this.type === 'image') {
            el.removeAttribute('height')
            el.removeAttribute('width')
          }
        }
      } catch (error) {
        console.error(error)
      }

      this.$emit('loaded')
    },
    async getSvgContent() {
      if (!cache[this.svg]) {
        cache[this.svg] = fetch(this.path).then((res) => {
          if (res.ok) {
            return res.text()
          }
        })
      }

      return cache[this.svg]
    },
  },
}
</script>

<template>
  <span
    v-if="svg"
    :data-icon="path"
    class="base-icon block"
    :aria-hidden="ariaHidden"
    :class="[
      color === 'primary' && 'text-primary',
      size === 'auto' && type !== 'image' && 'h-full w-full',
      size === 'xs' && type !== 'image' && 'h-[15px] w-[15px]',
      size === 'xxs' && type !== 'image' && 'h-[15px] w-[15px]',
      size === 'sm' && type !== 'image' && 'h-3.5 w-3.5',
      size === 'sme' && type !== 'image' && 'h-5 w-5',
      size === 'md' && type !== 'image' && 'h-6 w-6',
      size === 'lg' && type !== 'image' && 'h-[30px] w-[30px]',
      size === 'xl' && type !== 'image' && 'h-[35px] w-[35px]',
      size === '2xl' && type !== 'image' && 'h-10 w-10',
      size === '3xl' && type !== 'image' && 'h-12 w-12',
      size === '4xl' && type !== 'image' && 'h-[60px] w-[60px]',
      type === 'image' && 'h-auto w-auto',
    ]"
    :style="
      typeof size === 'number' && {
        width: `${size}px`,
        height: `${size}px`,
      }
    "
    v-html="html"
  />
  <img
    v-else
    class="block"
    :class="[
      size === 'auto' && type !== 'image' && 'h-full w-full',
      size === 'xxs' && type !== 'image' && 'h-[15px] w-[15px]',
      size === 'sm' && type !== 'image' && 'h-5 w-5',
      size === 'sme' && type !== 'image' && 'h-5 w-5',
      size === 'md' && type !== 'image' && 'h-6 w-6',
      size === 'lg' && type !== 'image' && 'h-[30px] w-[30px]',
      size === 'xl' && type !== 'image' && 'h-[35px] w-[35px]',
      size === '2xl' && type !== 'image' && 'h-10 w-10',
      size === '3xl' && type !== 'image' && 'h-12 w-12',
      size === '4xl' && type !== 'image' && 'h-[60px] w-[60px]',
      type === 'image' && 'h-auto w-auto',
    ]"
    :src="path"
    :alt="alt"
  />
</template>
