import omitBy from 'lodash-es/omitBy'
import { computed, reactive, ref } from 'vue'
import { AlgoliaWorker, getDefaultIndex } from '/~/core/algolia'
import { useExtensions } from '/~/composables/extensions'
import { useLogger } from '/~/composables/logger'
import { useSearch } from '/~/composables/search/use-search'
import { useUser, useUserEvents } from '/~/composables/user'
import {
  mapSelectedItems,
  mapSelectedBrands,
  defaultEstoreSettings,
} from '../helpers'

const { registerAlgoliaSearch } = useSearch()
const { getConfigByName, getManifestByName } = useExtensions()

const logger = useLogger('eStore')

const ESTORE_MODULE_NAME = 'estore'

const algolia = reactive(new AlgoliaWorker())
const estoreItem = ref(null)
const minRange = ref(0)
const maxRange = ref(0)

// DATA FLOW
const categories = ref({ data: [], page: 0, total: 0, loading: false })
const brands = ref({ data: [], page: 0, total: 0, loading: false })
const items = ref({ data: [], page: 0, total: 0, loading: false })
const catalog = ref({ data: [], page: 0, total: 0, loading: false })
const queryState = ref(null)

const config = computed(() => getConfigByName(ESTORE_MODULE_NAME))
const module = computed(() => getManifestByName(ESTORE_MODULE_NAME))
const isEstoreEnabled = computed(() => Boolean(module.value))
const defaultIndex = computed(() => getDefaultIndex(config.value))
const label = computed(() => module.value?.label ?? 'eStore')
const membershipLabels = computed(() =>
  (config.value?.membershipLabels ?? []).filter(Boolean)
)
const isEnabled = computed(() => {
  if (!config.value) {
    console.error('no config for eStore module')
    return false
  }

  const { user } = useUser()
  const isMembershipEnabled =
    membershipLabels.value.length === 0 ||
    membershipLabels.value.includes(user.value.membershipLabel)

  if (!isMembershipEnabled) {
    logger.debug(
      `eStore module is disabled for membership ${user.value.membershipLabel}`
    )
    return false
  }

  return true
})

const searchConfig = computed(() => config.value?.search ?? {})
const searchGroup = computed(() => searchConfig.value?.group ?? label.value)

const ready = computed(
  () => catalog.value.data.length > 0 && catalog.value.loading === false
)
const catalogIsLoading = computed(() => catalog.value.loading)
const routeState = computed(() => {
  const routeState = {
    params: {
      category: queryState.value?.selectedCategory,
    },
    query: {
      'selected-brands': queryState.value?.selectedBrands,
      'selected-items': queryState.value?.selectedItems,
      min: queryState.value?.priceRange.min,
      max: queryState.value?.priceRange.max,
      search: queryState.value?.searchString,
    },
  }

  routeState.query = omitBy(routeState.query, (v) => !v)
  return routeState
})

function setCatalog(payload) {
  if (payload.data) {
    const uniqueID = Math.random()

    payload.data = algolia.hits = payload.data.map((item, index) => {
      return { ...item, renderIndex: item.renderIndex || uniqueID + index }
    })
  }

  catalog.value = { ...catalog.value, ...payload }
}

function setBrands(payload) {
  if (payload.loading) {
    brands.value = { ...brands.value, loading: true }
    return
  }

  const selectedBrands = payload.selectedBrands.reduce((acc, tag) => {
    const found = find(payload.data, { id: tag })

    if (!found) {
      acc.push({
        id: tag,
        label: algolia.decodePath(tag),
      })
    }
    return acc
  }, [])

  brands.value = {
    ...brands.value,
    data: [
      ...selectedBrands,
      ...payload.data.filter(
        (item) =>
          !selectedBrands.find((selectedItem) => item.id === selectedItem.id)
      ),
    ],
    loading: false,
  }
}

function setItems(payload) {
  if (payload.loading) {
    items.value = { ...items.value, loading: true }
    return
  }

  const selectedItems = payload.selectedItems.reduce((acc, item) => {
    const found = find(payload.data, { id: item })

    if (!found) {
      acc.push({
        id: item,
        label: algolia.decodePath(item),
      })
    }
    return acc
  }, [])

  items.value = {
    ...items.value,
    data: [
      ...selectedItems,
      ...payload.data.filter(
        (item) =>
          !selectedItems.find((selectedItem) => item.id === selectedItem.id)
      ),
    ],
    loading: false,
  }
}

function setCategories(payload) {
  if (payload.loading) {
    categories.value = { ...categories.value, loading: true }
    return
  }

  categories.value = { ...categories.value, ...payload }
}

function initEstore() {
  if (!isEnabled.value) {
    return
  }

  algolia.setParams(config.value)

  registerAlgoliaSearch({
    group: 'eStore',
    order: 3,
    config: config.value,
    algolia: {
      params: {
        attributes: ['payload.images', 'payload.id', 'payload.name'],
      },
    },
    mapping: (item) => {
      const data = item?.payload ?? {}

      return {
        id: data.id,
        image: data?.images?.[0],
        label: data.name,
        target: {
          name: 'estore-product',
          params: {
            id: data.id,
          },
        },
      }
    },
  })
}

async function getCatalog(payload) {
  setCatalog({ loading: true })
  setCategories({ loading: true })
  setItems({ loading: true })
  setBrands({ loading: true })

  const {
    selectedCategory,
    selectedBrands,
    selectedItems,
    searchString,
    priceRange,
  } = payload

  queryState.value = payload

  const category = algolia.decodePath(selectedCategory)
  const categoryFilter = [`payload.categories:${category}`]
  const priceFilter = []
  const itemsFilter = []
  const brandsFilter = []

  if (selectedItems && selectedItems.length) {
    itemsFilter.push(...mapSelectedItems(algolia.decodePath, selectedItems))
  }

  if (selectedBrands && selectedBrands.length) {
    brandsFilter.push(...mapSelectedBrands(algolia.decodePath, selectedBrands))
  }

  if (priceRange) {
    if (minRange.value !== (priceRange.rawMin ?? priceRange.min)) {
      priceFilter.push(`payload.pricing.price_after_tax>=${priceRange.min}`)
    }

    if (maxRange.value !== (priceRange.rawMax ?? priceRange.max)) {
      priceFilter.push(`payload.pricing.price_after_tax<=${priceRange.max}`)
    }
  }

  await algolia.getDataMultiple({
    multiple: [
      {
        indexName: defaultIndex.value,
        query: searchString,
        params: {
          facetFilters: [categoryFilter, itemsFilter, brandsFilter].filter(
            (filter) => filter?.length > 0
          ),
          filters: 'payload.availability.status:Available',
          numericFilters: [
            ...priceFilter,
            `payload.availability.end_date_timestamp > ${Math.floor(
              Date.now() / 1000
            )}`,
          ],
        },
      },
      {
        indexName: defaultIndex.value,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.categories'],
        },
      },
      {
        indexName: defaultIndex.value,
        query: searchString,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.hierarchicalCategories.lvl1'],
          facetFilters: [categoryFilter, brandsFilter].filter(
            (filter) => filter
          ),
          numericFilters: [...priceFilter],
        },
      },
      {
        indexName: defaultIndex.value,
        query: searchString,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.brand'],
          facetFilters: [categoryFilter, itemsFilter].filter(
            (filter) => filter
          ),
          numericFilters: [...priceFilter],
        },
      },
      {
        indexName: defaultIndex.value,
        query: searchString,
        params: {
          page: 0,
          hitsPerPage: 1,
          attributesToRetrieve: [],
          facets: ['payload.pricing.price_after_tax'],
          facetFilters: [categoryFilter, brandsFilter, itemsFilter].filter(
            (filter) => filter
          ),
        },
      },
    ],
  })

  const response = {
    data: algolia.hits,
    total: algolia.total,
    error: algolia.message,
  }

  setCatalog({ ...response, loading: false })

  const [categoriesResult, itemsResult, brandsResult, pricesResult] =
    algolia.multiple

  const categoriesFacets = algolia.parseFacets(categoriesResult)

  if (categoriesFacets) {
    const categories = categoriesFacets.get('payload.categories')

    let categoriesData

    if (categories) {
      categories.values.unshift({
        label: 'All categories',
        count: categories.total,
        id: null,
      })

      categoriesData = categories.values
    } else {
      categoriesData = [{ label: 'All categories', count: -1, id: null }]
    }
    setCategories({ data: categoriesData, loading: false })
  }

  const itemsFacets = algolia.parseFacets(itemsResult)

  if (itemsFacets) {
    const items = itemsFacets.get('payload.hierarchicalCategories.lvl1')

    setItems({
      data: (items?.values ?? []).map((item) => {
        const decodedPath = algolia.decodePath(item.label)
        const splitPath = decodedPath.split('>')

        return {
          ...item,
          label: (splitPath?.[1] ?? item.label).trim(),
        }
      }),
      selectedItems,
      loading: false,
    })
  }

  const brandsFacets = algolia.parseFacets(brandsResult)

  if (brandsFacets) {
    const brands = brandsFacets.get('payload.brand')

    setBrands({
      data: brands?.values ?? [],
      selectedBrands,
      loading: false,
    })
  }

  const pricesStats = pricesResult?.facets_stats

  if (pricesStats) {
    const prices = pricesStats['payload.pricing.price_after_tax']

    minRange.value = prices?.min ?? defaultEstoreSettings.minRange
    maxRange.value = prices?.max ?? defaultEstoreSettings.maxRange
  }
}

async function getEstoreItem(id) {
  const { eStoreProductVisited } = useUserEvents()

  const { hits } = await algolia.getDataOnce({
    index: defaultIndex.value,
    search: {
      filters: `product_id:${id}`,
      distinct: true,
    },
  })

  estoreItem.value = hits?.[0] ?? null
  eStoreProductVisited(estoreItem.value)
  return estoreItem.value
}

export function useEstore() {
  return {
    algolia,
    ready,
    isEnabled,
    items,
    brands,
    minRange,
    maxRange,
    categories,
    catalogIsLoading,
    estoreItem,
    routeState,
    searchGroup,
    isEstoreEnabled,

    initEstore,
    setCatalog,
    getCatalog,
    getEstoreItem,
  }
}
