import api from '/~/core/api'
import { PayId } from './PayId'

type Params = {
  createUrl: string
}

export class PayIdManager {
  params: Params
  loading = false
  payId?: PayId

  constructor(params: Params) {
    this.params = params
  }

  async create() {
    try {
      this.loading = true
      const { data } = (await api.post(this.params.createUrl, {})) as {
        data: { id: string; reference: string }
      }
      const { id, reference } = data

      this.payId = new PayId(id, reference)
    } finally {
      this.loading = false
    }
  }
}
