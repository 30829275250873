export default [
  {
    path: '/privacy-policy',
    name: 'privacy-policy',
    meta: {
      title: 'Privacy Policy',
      footer: true,
      guestAccess: true,
    },
    component: () => import('/~/views/privacy-policy/privacy-view.vue'),
  },
  {
    path: '/terms-conditions',
    name: 'terms-conditions',
    meta: {
      title: 'Terms & Conditions',
      footer: true,
      guestAccess: true,
    },
    component: () => import('/~/views/terms/terms-view.vue'),
  },
  {
    path: '/faq',
    name: 'faq',
    meta: {
      title: 'FAQ',
      footer: true,
      guestAccess: true,
    },
    component: () => import('/~/views/faq/faq-view.vue'),
  },
  {
    path: '/3ds-troubleshooting-guide',
    name: '3ds-troubleshooting-guide',
    meta: {
      title: '3DS FAQ',
      footer: true,
      guestAccess: true,
    },
    component: () =>
      import(
        '/~/views/3ds-troubleshooting-guide/3ds-troubleshooting-guide-view.vue'
      ),
  },
]
