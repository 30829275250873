import type { RequestOptions } from '/~/types/api'
import emitter from '/~/core/emitter'
import { JwtStrategy } from '/~/core/Jwt/JwtStrategy'
import { OtpLocalStorage } from '/~/extensions/otp/composables/core/OtpLocalStorage'
import { api } from '/~/core'
import { AUTH_LOGIN_ROUTE } from '/~/router/router-constants'

export class DefaultJwtStrategy extends JwtStrategy implements IJwtStrategy {
  promise?: null | Promise<any>

  async generateToken(options: RequestOptions = {}) {
    const otpStorage = new OtpLocalStorage('login')
    const otp = otpStorage.restore() ?? {}

    if (!this.promise) {
      if (otp.otpId) {
        options['headers'] = {
          ...options['headers'],
          'Otp-Id': otp.otpId,
        }
      }

      this.promise = api.get<string>('/jwt', options)
    }

    let response: any

    try {
      response = await this.promise

      return response?.data?.token as string
    } catch (error) {
      console.error('Unable to generate JWT token', error)
      throw error
    } finally {
      if (response?.status === 401) {
        // if session cookie is expired, clear storage
        otpStorage.clear()
        // navigate to login after app.ts loads to allow guest routes
        emitter.emit('auth:unauthorized')
        emitter.emit('router:push', { name: AUTH_LOGIN_ROUTE })
      }

      this.promise = null
    }
  }
}
