import { computed, ref } from 'vue'
import emitter from '/~/core/emitter'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { PaymentMethodType } from '/~/composables/payment-methods/payment-methods-types'
import { api } from '/~/core'

type Ewallet = {
  availableBalance: string
  createdAt: string
  id: string
  number: string
  status: 'available'
  unclearedBalance: string
}

const ewallet = ref<Ewallet>()
const fetchingEwallet = ref(false)

const isEwalletEnabled = computed(() => {
  const { config } = usePaymentMethods()

  return config.value.some(
    (method: { type: PaymentMethodType }) => method.type === 'ew'
  )
})
const ewalletBalance = computed(() => {
  const availableBalance = Number(ewallet.value?.availableBalance ?? '0')

  if (isNaN(availableBalance)) {
    return 0
  }

  return Math.max(availableBalance, 0)
})
const ewalletUnclearedBalance = computed(() => {
  const unclearedBalance = Number(ewallet.value?.unclearedBalance ?? '0')

  if (isNaN(unclearedBalance)) {
    return 0
  }

  return Math.max(unclearedBalance, 0)
})

async function fetchBalance() {
  if (!isEwalletEnabled.value) {
    return
  }

  try {
    fetchingEwallet.value = true

    const { data } = await api.get('/v3/ewallets')

    ewallet.value = (data as any)[0]
  } catch (error: any) {
    console.error((error.data && error.data.message) || error)
  } finally {
    fetchingEwallet.value = false
  }
}

emitter.on('notifications:ewallet_balance_updated', fetchBalance)

export const useEwallet = () => ({
  fetchBalance,
  isEwalletEnabled,
  ewalletBalance,
  ewalletUnclearedBalance,
  fetchingEwallet,
})
