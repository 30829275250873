export function getCookie(name) {
  const token =
    document.cookie &&
    document.cookie.split(';').find((c) => c.trim().startsWith(`${name}=`))

  return decodeURIComponent(token ? token.split('=')[1] : '')
}

export function removeCookie(sKey, sPath, sDomain) {
  document.cookie =
    encodeURIComponent(sKey) +
    '=; expires=Thu, 01 Jan 1970 00:00:00 GMT' +
    (sDomain ? '; domain=' + sDomain : '') +
    (sPath ? '; path=' + sPath : '')
}
