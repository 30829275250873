import { reactive } from 'vue'
import EnrolmentOtp from '/~/extensions/otp/composables/core/Otp/EnrolmentOtp'
import LoginOtp from '/~/extensions/otp/composables/core/Otp/LoginOtp'

export class OtpFactory {
  static create(triggerPoint: Otp.TriggerPoint) {
    return OtpFactory.getOtpEntity(triggerPoint)
  }

  static restore(triggerPoint: Otp.TriggerPoint) {
    const otp = OtpFactory.getOtpEntity(triggerPoint)

    otp.restore()

    return otp
  }

  static clear(triggerPoint: Otp.TriggerPoint) {
    const otp = OtpFactory.getOtpEntity(triggerPoint)

    otp.getStorage().clear()
  }

  private static getOtpEntity(triggerPoint: Otp.TriggerPoint) {
    switch (triggerPoint) {
      case 'enrolment':
        return reactive(new EnrolmentOtp(triggerPoint))
      default:
        return reactive(new LoginOtp(triggerPoint))
    }
  }
}

export default OtpFactory
