import { ref } from 'vue'
import { BankAccountSchema, AccountPresentation } from '../../bank-account'

const initiateValues = {
  bsb: {
    transitNumber: '',
    institutionNumber: '',
  },
  accountNumber: '',
  accountName: '',
  termsAndConditions: false,
}

const form = ref(initiateValues)

const bankAccountSchema: BankAccountSchema = {
  fields: [
    {
      key: 'bsb',
      type: 'composite',
    },
    {
      type: 'string',
      key: 'accountNumber',
      label: 'Account Number',
      validation: {
        rules: 'required|numeric|min:7|max:12',
      },
      mask: {
        mask: '0000000[00000]',
        lazy: true,
      },
      analyticsLabel: 'Account number',
    },
    {
      type: 'string',
      key: 'accountName',
      label: 'Account Name',
      validation: {
        rules: 'required|min:2|max:100',
      },
      analyticsLabel: 'Account name',
    },
  ],
  types: {
    bsb: {
      fields: [
        {
          type: 'string',
          key: 'institutionNumber',
          label: 'Institution Number',
          validation: {
            rules: 'required|numeric|length:3',
          },
          mask: {
            mask: '000',
            lazy: true,
          },
          analyticsLabel: 'institutionNumber',
        },
        {
          type: 'string',
          key: 'transitNumber',
          label: 'Transit Number',
          validation: {
            rules:
              'required|numeric|length:5|canadian_bsb:@institutionNumber,institutionNumber',
          },
          mask: {
            mask: '00000',
            lazy: true,
          },
          analyticsLabel: 'transitNumber',
        },
      ],
    },
  },
}

const accountPresentation: AccountPresentation = {
  accountNumber: 'accountNumber',
  name: 'accountName',
}

export default {
  form,
  initiateValues,
  bankAccountSchema,
  accountPresentation,
}
