import { ref } from 'vue'
import {
  useBatchOrder,
  useBatchOrderStatusHandler,
} from '/~/composables/batch-order'
import { BatchOrderPendingPayees } from './core/BatchOrderPendingPayees'

const loading = ref(false)

export function useBatchOrderPendingPayees() {
  const { batchOrder, fetchBatchOrder, syncBatchOrderPayees } = useBatchOrder()

  const { batchOrderStatusHandler } = useBatchOrderStatusHandler()

  const pendingPayees = new BatchOrderPendingPayees({
    url: `/v3/batch-orders/${batchOrder.number}/transactions`,
    perPage: '10',
  })

  async function goToCheckout() {
    try {
      loading.value = true
      await syncBatchOrderPayees()
      await fetchBatchOrder(batchOrder.number)
      batchOrderStatusHandler.setReadyToPay()
      batchOrderStatusHandler.startCheckingLoop()
    } catch (error) {
      console.error(error)
    } finally {
      loading.value = false
    }
  }

  return {
    pendingPayees,
    goToCheckout,
    loading,
  }
}
