<script setup lang="ts">
import get from 'lodash-es/get'
import { ref } from 'vue'
import BaseButton from '/~/components/base/button/base-button'

const props = withDefaults(
  defineProps<{
    title?: string
    padding?: boolean | Record<string, unknown>
    shadow?: boolean
    titleCenter?: boolean
    closable?: boolean
  }>(),
  {
    title: '',
    padding: true,
    shadow: true,
    titleCenter: false,
    closable: true,
  }
)

const emit = defineEmits<(event: 'hide') => void>()

const pinned = ref(false)

const pad = (place: string) => {
  return props.padding && get(props.padding, place) !== false
}

const onBodyScroll = ({ target }: { target: HTMLElement }) => {
  pinned.value = props.shadow && target?.scrollTop > 0
}

const onClose = () => {
  if (props.closable) {
    emit('hide')
  }
}
</script>

<template>
  <div class="flex h-full w-full flex-col" @keyup.esc="onClose">
    <slot name="header">
      <header
        class="relative z-40 transition duration-200"
        :class="[pinned && 'shadow-md']"
      >
        <div class="flex items-center justify-between px-5 pt-6 pb-4 sm:px-12">
          <h2
            v-if="!titleCenter"
            class="text-xl text-eonx-neutral-800 sm:text-2xl"
          >
            <slot name="title">
              {{ title }}
            </slot>
          </h2>
          <h2 v-if="titleCenter" class="mx-auto text-xl sm:text-2xl">
            <slot name="title">
              {{ title }}
            </slot>
          </h2>
          <div class="absolute top-6 right-6 flex items-center space-x-5">
            <slot name="menu" />
            <base-button
              v-if="closable"
              ref="closeButton"
              icon="heroicons/solid/x-mark"
              :size="32"
              alt="close"
              :title="title"
              class="rounded-full"
              data-test="modal-close"
              @click="onClose"
            />
          </div>
        </div>
        <slot name="header-bottom" />
      </header>
    </slot>

    <div
      class="popup-layout flex-1 overflow-y-auto overflow-x-hidden"
      :class="[
        pad('body') && 'px-5 sm:px-8 sm:pt-8',
        pad('body') && !$slots.footer && 'pb-5 sm:pb-6',
      ]"
      @scroll="onBodyScroll"
    >
      <slot />
    </div>
    <footer
      v-if="$slots.footer"
      :class="[pad('footer') && 'px-5 pb-5 sm:px-8 sm:pb-6']"
    >
      <slot name="footer" />
    </footer>
  </div>
</template>
