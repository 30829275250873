export default class ImageDriver {
  source: string
  cdn: string
  operations: string

  /**
   * Sets sourceId attribute
   */
  constructor(source: string) {
    this.operations = ''
    this.cdn = ''
    this.source = source
  }

  get sourceId() {
    return this.source.split('://')[1] || ''
  }

  getCdn() {
    if (this.cdn) {
      return '//' + this.cdn.replace(/^\/*/, '')
    } else {
      console.warn('No cdn url has been provided')
    }
  }

  addOperation(operation: string) {
    this.operations += operation
    return this
  }

  format() {
    return this
  }

  quality() {
    return this
  }

  progressive() {
    return this
  }

  preview() {
    return this
  }

  resize() {
    return this
  }

  crop() {
    return this
  }

  scaleCrop() {
    return this
  }

  stretch() {
    return this
  }

  setfill() {
    return this
  }

  enhance() {
    return this
  }

  sharp() {
    return this
  }

  blur() {
    return this
  }

  grayscale() {
    return this
  }

  invert() {
    return this
  }

  autorotate() {
    return this
  }

  rotate() {
    return this
  }

  flip() {
    return this
  }

  mirror() {
    return this
  }

  /**
   * Resolves URL
   * return String
   */
  url() {
    return this.source
  }
}
