import BaseImageDriver from './driver-base'

export default class UploadCareImageDriver extends BaseImageDriver {
  constructor(source: string) {
    super(source)
    this.cdn = eonx.hosts.uploadcare
  }

  /**
   * Resolves URL
   * return String
   */
  url() {
    const { source, operations } = this
    const isNotResolved = /^uploadcare:/.test(source)
    const cut = (str: string) => str.replace(/\/*$/, '')

    let url = source

    if (isNotResolved) {
      url = `${this.getCdn()}/${this.sourceId}/`
    }

    if (operations) {
      url += cut(operations) + '/'
    }

    return url
  }

  /**
   * Converting an image to one of the following formats:
   * @argument string format: jpeg | png | webp | auto
   * return String
   */
  format(format: 'jpeg' | 'png' | 'webp' | 'auto' = 'auto') {
    return this.addOperation(`-/format/${format}/`)
  }

  /**
   * Setting the level of image quality.
   * @argument string value: normal | better | best | lighter | lightest
   * return String
   */
  quality(
    value:
      | 'normal'
      | 'better'
      | 'best'
      | 'lighter'
      | 'smart'
      | 'lightest' = 'normal'
  ) {
    return this.addOperation(`-/quality/${value}/`)
  }

  /**
   * Returns a progressive image.
   * @argument string param: yes | no
   * return String
   */
  progressive(param: 'yes' | 'no' = 'yes') {
    return this.addOperation(`-/progressive/${param}/`)
  }

  /**
   * Reduces an image proportionally in order for it to fit into the given dimensions.
   * @argument string dimensions: (e.g '2048x2048' pixels)
   * return String
   */
  preview(dimensions = '2048x2048') {
    return this.addOperation(`-/preview/${dimensions}/`)
  }

  /**
   * Resizes an image to fit into the specified dimensions.
   * @argument string dimensions: (e.g '2048x2048' pixels)
   * return String
   */
  resize(dimensions = '2048x2048') {
    return this.addOperation(`-/resize/${dimensions}/`)
  }

  /**
   * Crops an image to specified dimensions, with specified offset.
   * @argument string dimensions: (e.g '2048x2048' pixels)
   * @argument string position: (e.g '2048x2048' pixels) | center
   * return String
   */
  crop(dimensions = '2048x2048', position?: string) {
    return this.addOperation(
      `-/crop/${dimensions}/` + (position ? `${position}/` : '')
    )
  }

  /**
   * Scales down an image until one of the dimensions gets equal to the specified one, the rest is cropped.
   * @argument string dimensions: (e.g '2048x2048' pixels)
   * @argument string position: (e.g '2048x2048' pixels)
   * return String
   */
  scaleCrop(dimensions = '2048x2048', position?: string) {
    return this.addOperation(
      `-/scale_crop/${dimensions}/` + (position ? `${position}/` : '')
    )
  }

  /**
   * Sets the behavior of the resize operation if a source image is smaller than the specified (resulting) dimensions.
   * @argument string mode: on | off | fill
   * return String
   */
  stretch(mode: 'on' | 'off' | 'fill' = 'on') {
    return this.addOperation(`-/stretch/${mode}/`)
  }

  /**
   * Sets the fill color used with crop or when converting an image with alpha channel to JPEG.
   * @argument string color: hexadecimal
   * return String
   */
  setfill(color: 'hexadecimal' = 'hexadecimal') {
    return this.addOperation(`-/setfill/${color}/`)
  }

  /**
   * Auto-enhances an image by performing auto levels, auto contrast, and saturation sharpening.
   * @argument string strength: 0 to 100
   * return String
   */
  enhance(strength = 100) {
    return this.addOperation(`-/enhance/${strength}/`)
  }

  /**
   * Sharpens an image, might be especially useful in case of post-downscale images.
   * @argument string strength: 0 to 20
   * return String
   */
  sharp(strength = 20) {
    return this.addOperation(`-/sharp/${strength}/`)
  }

  /**
   * Blurs an image by the :strength factor.
   * @argument string strength: up to 5000
   * return String
   */
  blur(strength = 5000) {
    return this.addOperation(`-/blur/${strength}/`)
  }

  /**
   * Desaturates an image.
   * return String
   */
  grayscale() {
    return this.addOperation('-/grayscale/')
  }

  /**
   * Inverts an image, rendering a 'negative' of the input image.
   * return String
   */
  invert() {
    return this.addOperation('-/invert/')
  }

  /**
   * The default behavior of our CDN is to parse EXIF tags of the original images and rotate them accordingly to
   * the value of the “Orientation” tag.
   * @argument string enabled: yes | no
   * return String
   */
  autorotate(enabled: 'yes' | 'no' = 'yes') {
    return this.addOperation(`-/autorotate/${enabled}/`)
  }

  /**
   * Right-angle image rotation, counterclockwise.
   * @argument string angle: must be a multiple of 90
   * return String
   */
  rotate(angle = 90) {
    return this.addOperation(`-/rotate/${angle}/`)
  }

  /**
   * Flips an image.
   * return String
   */
  flip() {
    return this.addOperation('-/flip/')
  }

  /**
   * Mirrors an image.
   * return String
   */
  mirror() {
    return this.addOperation('-/mirror/')
  }
}
