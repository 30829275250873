class FrameExchange {
  constructor() {
    this.handlers = {}

    if (window.addEventListener) {
      window.addEventListener('message', this.messageHandler.bind(this), false)
    } else if (window.attachEvent) {
      window.attachEvent('onmessage', this.messageHandler.bind(this))
    }
  }

  addHandler(type, handler) {
    this.handlers[type] = handler
  }

  removeHandler(type) {
    delete this.handlers[type]
  }

  messageHandler(message) {
    if (!message.isTrusted) return

    try {
      let data = message.data

      if (typeof message.data === 'string' && /^(\[|\{)/.test(message.data)) {
        data = JSON.parse(message.data)
      }

      const handler = this.handlers[data.type]

      if (handler instanceof Function) {
        handler(data.payload)
      }
    } catch (error) {
      console.error(error)
    }
  }

  sendToParent(type, payload) {
    const message = JSON.stringify({
      type,
      payload,
    })

    if (window.parent.postMessage instanceof Function) {
      window.parent.postMessage(message, '*')
    }
  }
}

export default new FrameExchange()
