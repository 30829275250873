import BaseImageDriver from './driver-base'

export default class StorageImageDriver extends BaseImageDriver {
  /**
   * Sets cdn attribute
   */
  constructor(source: string) {
    super(source)
    this.cdn = eonx.hosts.storage
  }

  /**
   * Resolves URL
   * return String
   */
  url() {
    return this.getCdn() + this.sourceId
  }
}
