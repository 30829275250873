<script>
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'base-button-transition',
  components: {
    BaseIcon,
  },
  inheritAttrs: false,
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['click'],
}
</script>

<template>
  <button
    v-bind="$attrs"
    type="button"
    class="flex h-16 w-full items-center rounded-lg border-2 border-solid border-transparent px-5 transition duration-200"
    :disabled="disabled"
    :class="[disabled ? 'cursor-default opacity-75' : 'hover:border-primary']"
    v-on="$listeners"
  >
    <div class="w-11/12">
      <slot />
    </div>
    <base-icon svg="plain/chevron-right" size="sm" class="ml-auto shrink-0" />
  </button>
</template>
