import { reactive } from 'vue'
import { BatchOrderLoader, BatchOrderLoaderParams } from './BatchOrderLoader'
import { BatchOrderPagination } from './BatchOrderPagination'
import { BatchOrderTransaction } from './BatchOrderTransaction'
import { BatchOrderTransactionList } from './BatchOrderTransactionList'

export class BatchOrderPendingPayees {
  transactionList: BatchOrderTransactionList
  pagination: BatchOrderPagination
  loader: BatchOrderLoader

  constructor(params: BatchOrderLoaderParams) {
    this.transactionList = reactive(new BatchOrderTransactionList())
    this.pagination = reactive(new BatchOrderPagination())
    this.loader = reactive(new BatchOrderLoader(params))
  }

  async load(page: number) {
    const { pagination, items } = (await this.loader.load(page)) as {
      pagination: BatchOrderPagination
      items: BatchOrderTransaction[]
    }

    this.pagination.setItems(pagination)
    this.transactionList.setItems(
      items.map(
        (item) => new BatchOrderTransaction({ ...item, name: item.payee?.name })
      )
    )
  }
}
