import { BatchOrderTransactionsTab } from './BatchOrderTransactionsTab'

export class BatchOrderTransactionsTabList {
  list: BatchOrderTransactionsTab[] = []
  activeTab: BatchOrderTransactionsTab = new BatchOrderTransactionsTab(
    '',
    '',
    []
  )

  setItems(list: BatchOrderTransactionsTab[]) {
    this.list = list.map(
      (item) =>
        new BatchOrderTransactionsTab(
          item.name,
          item.status,
          item.queryStatuses
        )
    )
    this.setActiveTab(this.list[0])
  }

  setActiveTab(tab: BatchOrderTransactionsTab) {
    for (const item of this.list) {
      if (item.status === tab.status) {
        item.isActive = true
        this.activeTab = item
      } else {
        item.isActive = false
      }
    }
  }

  setStatusItemsCount(
    statusItemsCount: Array<{ status: string; count: number }>
  ) {
    for (const statusItem of statusItemsCount) {
      for (const item of this.list) {
        if (item.queryStatuses.includes(statusItem.status)) {
          item.addStatusItemCount(statusItem.count)
        }
      }
    }
  }
}
