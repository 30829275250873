import throttle from 'lodash-es/throttle'
import Vue from 'vue'

const LOCK_VIEWPORT_CLASS = 'overflow-hidden'

const breakpoints = {
  xxs: 360,
  xs: 576,
  sm: 896,
  md: 1025,
  lg: 1440,
  xl: 1920,
}

type Breakpoints = { [K in keyof typeof breakpoints]: number }

const ui = new Vue({
  data() {
    return {
      ready: false,
      os: '',
      devType: '',
      width: 0,
      height: 0,
      screen: '',
      breakpoints,
    } as {
      ready: boolean
      os: string
      devType: string
      width: number
      height: number
      screen: string
      breakpoints: Breakpoints
    }
  },
  computed: {
    reversedSizes() {
      const keys = Object.keys(this.breakpoints) as (keyof Breakpoints)[]
      const sizes = [{ id: 'base', value: 0 }]

      for (const key of keys) {
        sizes.push({ id: key, value: this.breakpoints[key] })
      }

      return sizes.sort((a, b) => b.value - a.value).map((i) => i.id)
    },
    mobile(): boolean {
      return this.width < this.breakpoints.sm
    },
    tablet(): boolean {
      return !this.mobile && !this.desktop
    },
    tablet2024(): boolean {
      return !this.mobile && this.width <= 1032
    },
    desktop(): boolean {
      return this.width >= this.breakpoints.md
    },
    isIE(): boolean {
      const ua = window.navigator.userAgent

      return (
        !!window.MSInputMethodContext &&
        !!document.documentMode &&
        (ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1)
      )
    },
  },
  created() {
    if (document.fonts) {
      document.fonts.ready.then(() => {
        this.ready = true
      })
    } else {
      this.ready = true
    }
    // document.fonts.onloadingdone = function(fontFaceSetEvent) {}
    this.setAgentInfo()
    this.onResize()
    window.addEventListener('resize', throttle(this.onResize, 100))
  },
  beforeDestroy() {
    window.removeEventListener('resize', throttle(this.onResize, 100))
  },
  methods: {
    onResize() {
      this.height = window.innerHeight
      this.width = window.innerWidth

      this.setScreenParams()
    },
    setScreenParams() {
      const bp = this.breakpoints
      const screenWidth = window.innerWidth
      let screenMarker = 'base'

      if (screenWidth >= bp.xxs && screenWidth < bp.xs) {
        screenMarker = 'xxs'
      } else if (screenWidth >= bp.xs && screenWidth < bp.sm) {
        screenMarker = 'xs'
      } else if (screenWidth >= bp.sm && screenWidth < bp.md) {
        screenMarker = 'sm'
      } else if (screenWidth >= bp.md && screenWidth < bp.lg) {
        screenMarker = 'md'
      } else if (screenWidth >= bp.lg && screenWidth < bp.xl) {
        screenMarker = 'lg'
      } else if (screenWidth >= bp.xl) {
        screenMarker = 'xl'
      }

      this.screen = screenMarker
    },
    lockViewport() {
      const target = document.documentElement
      const scrollbarWidth = window.innerWidth - target.clientWidth

      if (this.mobile) {
        const appMain = document.getElementById('app-main')

        if (appMain) {
          appMain.style.marginTop = -appMain.scrollTop + 'px'
        }
      }

      this.insertFakeScrollbar(scrollbarWidth)
      document.documentElement.style.setProperty(
        '--fake-scrollbar-width',
        `${scrollbarWidth}px`
      )
      target.style.paddingRight = scrollbarWidth + 'px'
      target.classList.add(LOCK_VIEWPORT_CLASS)
    },
    unlockViewport() {
      this.removeFakedScrollbar()
      document.documentElement.style.paddingRight = ''
      document.documentElement.style.removeProperty('--fake-scrollbar-width')
      document.documentElement.classList.remove(LOCK_VIEWPORT_CLASS)

      if (this.mobile) {
        const appMain = document.getElementById('app-main')

        if (appMain) {
          appMain.style.marginTop = ''
        }
      }
    },
    insertFakeScrollbar(scrollbarWidth: number | string) {
      const existingFakedScrollbar: HTMLElement | null =
        document.querySelector('.faked-scrollbar')

      let fakedScrollbar: HTMLElement

      if (existingFakedScrollbar) {
        fakedScrollbar = existingFakedScrollbar
      } else {
        fakedScrollbar = document.createElement('div')
        fakedScrollbar.classList.add('faked-scrollbar')
      }

      fakedScrollbar.style.width = `${scrollbarWidth}px`

      if (!existingFakedScrollbar) {
        document.body.appendChild(fakedScrollbar)
      }
    },
    removeFakedScrollbar() {
      const existingFakedScrollbar = document.querySelector('.faked-scrollbar')

      if (existingFakedScrollbar) {
        document.body.removeChild(existingFakedScrollbar)
      }
    },
    setAgentInfo() {
      const agent = navigator.userAgent || navigator.vendor
      let devType = ''
      let os = ''

      if (agent.match(/Android/i)) {
        os = 'android'
        devType = 'mobile'
      } else if (agent.match(/iPhone|iPad|iPod/i)) {
        os = 'ios'
        devType = 'mobile'
      } else if (agent.match(/BlackBerry/i)) {
        os = 'blackberry'
        devType = 'mobile'
      } else if (agent.match(/Windows Phone/i)) {
        os = 'windowsphone'
        devType = 'mobile'
      } else if (agent.match(/webOS/i)) {
        os = 'webos'
        devType = 'media'
      } else if (agent.match(/Mac/)) {
        os = 'mac'
        devType = 'desktop'
      } else if (agent.match(/Linux/i)) {
        os = 'linux'
        devType = 'desktop'
      } else if (agent.match(/Windows/i)) {
        os = 'windows'
        devType = 'desktop'
      }

      this.os = os
      this.devType = devType
    },
    getByScreenSize(options: { [key: string]: string | number }) {
      if (this.screen in options) {
        return options[this.screen]
      }

      const size = this.reversedSizes
        .slice(this.reversedSizes.findIndex((size) => size === this.screen))
        .find((size) => size in options)

      return size && options[size] ? options[size] : null
    },
  },
})

export default ui
