import get from 'lodash-es/get'
import omit from 'lodash-es/omit'

export function transformQueriesForward(route, name, value) {
  const query = { ...route.query, [name]: value }
  const params = { ...route.params, preventTopScrolling: true }

  return {
    query: value.length ? query : omit(query, [name]),
    params: params,
  }
}

export function transformQueriesBack(route, name) {
  let items = route.query[name] || []

  if (typeof items === 'string') items = [items]
  return items || []
}

export function extractAlgoliaSetting(route) {
  return {
    index: get(route, 'meta.config.index', ''),
    appId: get(route, 'meta.config.algolia_app_id', ''),
    appKey: get(route, 'meta.config.algolia_search_key', ''),
  }
}

export function mapSelectedItems(decodePath, selectedItems) {
  if (selectedItems.length) {
    return selectedItems.map((name) => {
      return 'payload.hierarchicalCategories.lvl1:' + decodePath(name)
    })
  }
  return []
}

export function mapSelectedBrands(decodePath, selectedBrands) {
  if (selectedBrands.length) {
    return selectedBrands.map((name) => {
      return 'payload.brand:' + decodePath(name)
    })
  }
  return []
}

export const defaultEstoreSettings = {
  minRange: 0,
  maxRange: 15000,
  ITEMS_DEBOUNCE: 500,
}
