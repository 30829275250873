interface JwtStorageValue {
  token: string
}

export interface BroadcastMessage {
  key: string
  value: JwtStorageValue | null
  senderId: string
}

class InMemoryStorage<T> {
  private static instance: InMemoryStorage<any>
  private store: Map<string, T> = new Map()

  // eslint-disable-next-line no-useless-constructor
  private constructor() {} // private constructor: singleton pattern

  static getInstance<U>(): InMemoryStorage<U> {
    if (!InMemoryStorage.instance) {
      InMemoryStorage.instance = new InMemoryStorage<U>()
    }
    return InMemoryStorage.instance
  }

  set(key: string, value: T): void {
    this.store.set(key, value)
  }

  get(key: string): T | undefined {
    return this.store.get(key)
  }

  remove(key: string): void {
    this.store.delete(key)
  }

  clear(): void {
    this.store.clear()
  }

  toJSON(): Record<string, T> {
    return Object.fromEntries(this.store)
  }
}

function generateUniqueId(): string {
  const timestamp = Date.now().toString(36)
  const randomStr = Math.random().toString(36).substring(2, 7)

  return `${timestamp}-${randomStr}`
}

const GLOBAL_JWT_BROADCAST_INSTANCE_ID = generateUniqueId()

const GLOBAL_JWT_BROADCAST_CHANNEL = new BroadcastChannel('global-jwt-storage')
const GLOBAL_JWT_IN_MEMORY_STORAGE =
  InMemoryStorage.getInstance<JwtStorageValue>()

GLOBAL_JWT_BROADCAST_CHANNEL.onmessage = (
  event: MessageEvent<BroadcastMessage>
) => {
  const { key, value, senderId } = event.data

  if (senderId !== GLOBAL_JWT_BROADCAST_INSTANCE_ID) {
    if (value) {
      GLOBAL_JWT_IN_MEMORY_STORAGE.set(key, value)
    } else {
      GLOBAL_JWT_IN_MEMORY_STORAGE.remove(key)
    }
    console.log(`Data updated in another tab: ${JSON.stringify(value)}`, {
      key,
      value,
      senderId,
    })
  }
}

export class JwtInMemoryStorage implements Jwt.Storage {
  protected readonly key = 'jwt'

  put(token?: string): void {
    const value = token ? { token } : null

    this.updateStorage(value)
    GLOBAL_JWT_BROADCAST_CHANNEL.postMessage({
      key: this.key,
      value,
      senderId: GLOBAL_JWT_BROADCAST_INSTANCE_ID,
    })
  }

  restore(): string {
    return GLOBAL_JWT_IN_MEMORY_STORAGE.get(this.key)?.token ?? ''
  }

  clear(): void {
    GLOBAL_JWT_IN_MEMORY_STORAGE.remove(this.key)
    GLOBAL_JWT_BROADCAST_CHANNEL.postMessage({
      key: this.key,
      value: null,
      senderId: GLOBAL_JWT_BROADCAST_INSTANCE_ID,
    })
  }

  private updateStorage(value: JwtStorageValue | null): void {
    if (value) {
      GLOBAL_JWT_IN_MEMORY_STORAGE.set(this.key, value)
    } else {
      GLOBAL_JWT_IN_MEMORY_STORAGE.remove(this.key)
    }
  }
}
