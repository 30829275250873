<script>
import './button-icon.scss'
import BaseLoader from '/~/components/base/loader/base-loader.vue'
import BaseIcon from '/~/components/base/icon/base-icon.vue'

export default {
  name: 'base-button-icon',
  components: {
    BaseLoader,
    BaseIcon,
  },
  props: {
    type: {
      type: String,
      default: 'button',
    },
    icon: {
      type: String,
      default: '',
    },
    size: {
      type: [String, Number],
      default: 'md',
    },
    loading: {
      type: Boolean,
      default: false,
    },
    alt: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    padding: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    label() {
      return `${this.title} ${this.alt}`
    },
  },
}
</script>

<template>
  <button
    v-bind="$attrs"
    :type="type"
    class="rounded-full"
    :class="['button-icon', loading && 'button-icon--loading']"
    :aria-label="label"
    v-on="$listeners"
  >
    <span v-if="loading" ref="loader" class="button-icon__loader">
      <slot name="loader">
        <base-loader ref="the-loader" size="xs" />
      </slot>
    </span>
    <base-icon
      :svg="icon"
      :size="size"
      :alt="alt"
      :style="{ padding: `${padding}px` }"
    />
  </button>
</template>
