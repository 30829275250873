import { Jwt } from '/~/core/jwt'
import { EnrolmentJwtStrategy } from '/~/core/Jwt/JwtStrategy/EnrolmentJwtStrategy'
import Otp from '/~/extensions/otp/composables/core/Otp'

export class EnrolmentOtp extends Otp {
  public setJwt(token?: string) {
    if (!this.jwt) {
      this.jwt = new Jwt()
      this.jwt.setJwtStrategy(new EnrolmentJwtStrategy(this.channel))
    }

    if (token) {
      this.jwt.set(token)
    }
  }
}

export default EnrolmentOtp
