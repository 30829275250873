import { ref } from 'vue'
import { BankAccountSchema, AccountPresentation } from '../../bank-account'

const initiateValues = {
  bsb: '',
  accountNumber: '',
  accountName: '',
  termsAndConditions: false,
}

const form = ref(initiateValues)

const bankAccountSchema: BankAccountSchema = {
  fields: [
    {
      type: 'string',
      key: 'bsb',
      label: 'BSB',
      validation: {
        rules: 'required|bsb',
      },
      placeholder: '000-000',
      mask: {
        mask: '000-000',
        lazy: true,
      },
      unmaskedModelValue: true,
      analyticsLabel: 'BSB',
    },
    {
      type: 'string',
      key: 'accountNumber',
      label: 'Account Number',
      validation: {
        rules: 'required|min:6|max:9',
      },
      mask: {
        mask: '000000000',
        lazy: true,
      },
      analyticsLabel: 'Account number',
    },
    {
      type: 'string',
      key: 'accountName',
      label: 'Account Name',
      validation: {
        rules: 'required|min:2|max:100',
      },
      analyticsLabel: 'Account name',
    },
  ],
  types: {},
}

const accountPresentation: AccountPresentation = {
  accountNumber: 'accountNumber',
  name: 'accountName',
  bsb: 'bsb',
}

export default {
  form,
  initiateValues,
  bankAccountSchema,
  accountPresentation,
}
