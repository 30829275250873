export enum PayeeRowStatuses {
  empty = 'empty',
  creating = 'creating',
  created = 'created',
  failed = 'failed',
}

export class BatchOrderPayee {
  hash?: string
  accountName?: string
  accountNumber?: string
  bsb?: string
  abn?: string
  entityName?: string
  status?: PayeeRowStatuses
}
