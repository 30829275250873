export class ThirdPartyOwner {
  protected raw: ThirdPartyLogin.Owner.Raw

  constructor(raw = {}) {
    this.raw = raw
  }

  get externalId() {
    return this.raw.externalId
  }

  get owner() {
    return this.raw.owner
  }
}
