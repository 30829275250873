import { CMSPageId } from '/~/types/cms'

export function getConfigNameByRoute(route: string): CMSPageId {
  const routeWithConfig = route.matched
    .map((route) => ({ meta: route.meta }))
    .reverse()
    .find((route) => route.meta.page)

  return routeWithConfig ? routeWithConfig.meta.page : null
}
