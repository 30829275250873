export const DICT = {
  travel: () => import('/~/extensions/travel/main'),
  services: () => import('/~/extensions/services/main'),
  dining: () => import('/~/extensions/dining/main'),
  // ids: () => import('/~/extensions/ids/main'),
  workplace: {
    roots: ['/rec-room'],
    src: () => import('/~/extensions/rec-room/main'),
  },
  estore: () => import('/~/extensions/estore/main'),
  'gift-cards': () => import('/~/extensions/giftcards/main'),
  'gift-cards-cinema': () => import('/~/extensions/gift-cards-cinema/main'),
  'online-offers': () => import('/~/extensions/online-offers/main'),
  competitions: () => import('/~/extensions/competitions/main'),
  'pre-sales': () => import('/~/extensions/pre-sales/main'),
  'community-hub': () => import('/~/extensions/community-hub/main'),
  qrconnect: () => import('/~/extensions/qr-connect/main'),
  health: () => import('/~/extensions/health/main'),
  'rec-room': () => import('/~/extensions/rec-room/main'),
  'bank-file-upload': {
    roots: ['/bank-file-upload', '/payments/bank-files'],
    src: () => import('/~/extensions/bank-file-upload/main'),
  },
  payroll: {
    roots: ['/payroll', '/payments/payroll'],
    src: () => import('/~/extensions/payroll/main'),
  },
  'lead-submission': () => import('/~/extensions/lead-submission/main'),
  'scheduled-payments': {
    roots: ['/scheduled-payments', '/payments/scheduled-payments'],
    src: () => import('/~/extensions/scheduled-payments/main'),
  },
  'ticket-master': () => import('/~/extensions/ticket-master/main'),
  otp: () => import('/~/extensions/otp/main'),
  'third-party-login': () => import('/~/extensions/third-party-login/main'),
}
