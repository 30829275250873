import { ActivityItemPaymentMethodType } from '/~/types/api'

export enum PaymentMethodType {
  points = 'ps',
  eWallet = 'ew',
  creditCard = 'cc',
  bankAccount = 'db',
  couponCode = 'cp',
  payId = 'pi',
}

export enum PaymentMethodMappedType {
  pi = 'payId',
  ps = 'points',
  ew = 'ewallet',
  cc = 'creditCard',
  db = 'directDebit',
  cp = 'couponCode',
}

export const ActivityItemPaymentMethodTypeMap: Record<
  ActivityItemPaymentMethodType,
  PaymentMethodType
> = {
  AmericanExpressCredit: PaymentMethodType.creditCard,
  MastercardCredit: PaymentMethodType.creditCard,
  MastercardDebit: PaymentMethodType.creditCard,
  MastercardPrepaid: PaymentMethodType.creditCard,
  VisaCredit: PaymentMethodType.creditCard,
  VisaDebit: PaymentMethodType.creditCard,
  VisaPrepaid: PaymentMethodType.creditCard,
  BankAccountSender: PaymentMethodType.bankAccount,
  PointsAccount: PaymentMethodType.points,
  Ewallet: PaymentMethodType.eWallet,
  ProviderFloat: PaymentMethodType.couponCode,
}
