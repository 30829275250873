import Vue, { computed, reactive, readonly, ref } from 'vue'
import api from '/~/core/api'
import modal from '/~/core/mdl'
import { BatchOrder, BatchOrderType } from '/~/composables/batch-order'
import { usePoints } from '/~/composables/points'
import router from '/~/router'

const BANK_FILE_BATCH_ORDER_TYPE = 'bank-file'
const PAYROLL_BATCH_ORDER_TYPE = 'payroll'

const { calculatePointsEarnedForPayment } = usePoints()

const batchOrderFlowType = ref<BatchOrderType>('business')

const isIndividualBatchOrderFlowType = computed(
  () => batchOrderFlowType.value === 'individual'
)
const batchOrderFlowPrefix = computed(() =>
  isIndividualBatchOrderFlowType.value
    ? PAYROLL_BATCH_ORDER_TYPE
    : BANK_FILE_BATCH_ORDER_TYPE
)

const batchOrder = reactive(new BatchOrder())
const batchOrderPointsToEarn = computed(() =>
  calculatePointsEarnedForPayment(batchOrder.subtotal)
)
const isBatchOrderLoading = ref(false)

const isPayeesSyncedBatchOrder = computed(() =>
  ['payees_synced'].includes(batchOrder.status)
)

function setBatchOrderLoading(isLoading: boolean) {
  isBatchOrderLoading.value = isLoading
}

function setBatchOrder(batchOrderData: BatchOrder | undefined = undefined) {
  Object.assign(batchOrder, new BatchOrder(batchOrderData ?? {}))
}

async function syncBatchOrderPayees() {
  setBatchOrderLoading(true)

  try {
    await api.post(
      `/v3/batch-orders/${batchOrder.number}/transactions/sync-payees/`
    )
  } catch (error) {
    console.log(error)
  }
}

async function fetchBatchOrder(
  batchOrderNumber: string,
  options: {
    checkType?: boolean
    useLoading: boolean
  } = {
    useLoading: true,
  }
) {
  if (options.useLoading) {
    setBatchOrderLoading(true)
  }

  const response = await api.get(`/v3/batch-orders/${batchOrderNumber}`)

  if (
    response.data?.type !== batchOrderFlowType.value &&
    options.checkType !== false
  ) {
    throw new Error(`Order ${batchOrderNumber} was not found.`)
  }

  setBatchOrder(response.data)

  if (options.useLoading) {
    setBatchOrderLoading(false)
  }

  return response.data
}

async function cancelBatchOrder() {
  setBatchOrderLoading(true)

  await api.put(`/v3/batch-orders/${batchOrder.number}`, {
    status: 'cancelled',
  })

  setBatchOrder()
  setBatchOrderLoading(false)
}

async function cancelOrder() {
  try {
    await cancelBatchOrder()
    router.push({
      name: `${batchOrderFlowPrefix.value}-upload`,
    })
  } catch (error) {
    Vue.notify({
      type: 'error',
      text: 'There was an error cancelling an order. Please try again.',
    })
  }
}

function onCancelBatchOrder() {
  modal.show('confirm-v3', {
    props: {
      title: 'Cancel batch payment',
      description: `Are you sure you want to cancel?

        This will cancel the entire batch payment. Any payees already added will be available for any future payments, and any payees submitted will be reviewed.`,
      width: 'xs',
      showCancel: false,
      confirmLabel: 'Cancel batch payment',
      onConfirm: async () => {
        await cancelOrder()
      },
    },
  })
}

async function createBatchOrder(payload: Record<string, unknown>) {
  setBatchOrderLoading(true)

  payload.type = batchOrderFlowType.value

  const response = await api.post('/v3/batch-orders', payload)

  setBatchOrder(response.data)
}

export function useBatchOrder() {
  return {
    BANK_FILE_BATCH_ORDER_TYPE,
    PAYROLL_BATCH_ORDER_TYPE,
    batchOrderFlowType,
    isIndividualBatchOrderFlowType,
    batchOrderFlowPrefix,
    batchOrder: readonly(batchOrder),
    batchOrderPointsToEarn,
    isBatchOrderLoading: readonly(isBatchOrderLoading),
    setBatchOrderLoading,
    setBatchOrder,
    fetchBatchOrder,
    cancelBatchOrder,
    cancelOrder,
    onCancelBatchOrder,
    syncBatchOrderPayees,
    isPayeesSyncedBatchOrder,
    createBatchOrder,
  }
}
