export function pluralize(value: number | string, str: string) {
  return `${str}${Number(value) !== 1 ? 's' : ''}`
}

export function capitalizeFirstLetter(str: string) {
  str = str.trim()

  return `${str.charAt(0).toUpperCase()}${str.slice(1)}`
}

export function capitalize(str = '') {
  const words = str.split(' ')
  const result = []

  for (const word of words) {
    const _word = word.trim()

    if (_word) {
      result.push(
        `${_word.charAt(0).toUpperCase()}${_word.slice(1).toLowerCase()}`
      )
    }
  }

  return result.join(' ')
}

export function kebabToCamel(str: string) {
  str = str.trim()
  return str.replace(/-./g, (m) => m.toUpperCase()[1])
}

export function camelToKebab(str: string) {
  str = str.trim()
  return str.replace(/([a-z0-9])([A-Z])/g, '$1-$2').toLowerCase()
}

export function formatPhone(value: string | number | undefined) {
  if (!value) return ''

  const stringValue = `${value}`.replace(/[^0-9+()]/g, '')

  // Australia

  if (stringValue.startsWith('04')) {
    return stringValue.replace(/(\d{4})(\d{3})(\d{3})/, '$1 $2 $3')
  }

  if (stringValue.startsWith('+6')) {
    return stringValue.replace(/(\d{2})(\d{3})(\d{3})(\d{3})/, '$1 $2 $3 $4')
  }

  // US / Canada

  if (stringValue.startsWith('(0')) {
    return stringValue.replace(/\((\d{3})\)(\d{4})(\d{4})/, '($1) $2 - $3')
  }

  if (stringValue.startsWith('(')) {
    return stringValue.replace(/\((\d{3})\)(\d{3})(\d{4})/, '($1) $2 - $3')
  }

  if (stringValue.startsWith('+1')) {
    return stringValue.replace(/(\d)(\d{3})(\d{7})/, '$1 $2 $3')
  }

  // UK

  if (stringValue.startsWith('+4')) {
    return stringValue.replace(/(\d{2})(\d{4})(\d{5})/, '$1 $2 $3')
  }

  // Default (UK)

  return stringValue.replace(/(\d{3})(\d{3})(\d{4})/, '$1-$2-$3')
}

export function formatBsb(value: string | number) {
  if (value) {
    const matches = String(value).match(/.{1,3}/g)

    if (matches) {
      return matches.join('-')
    }
  }

  return value
}

export function isEmptyText(value: string) {
  return value.replace(/(<([^>]+)>)/gi, '').trim().length === 0
}

export function getNumberWithOrdinal(n: number): string {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100

  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

/** trim  and remove repeatable whitespace from string */
export function clearWhitespace(string: string) {
  return string.replace(/[\n\r]+|[\s]{2,}/g, ' ').trim()
}
