import { Jwt } from '/~/core/jwt'
import { DefaultJwtStrategy } from '/~/core/Jwt/JwtStrategy/DefaultJwtStrategy'
import Otp from '/~/extensions/otp/composables/core/Otp'

export class LoginOtp extends Otp {
  constructor(triggerPoint: Otp.TriggerPoint) {
    super(triggerPoint)

    this.setJwt()
  }

  public setJwt(token?: string) {
    if (!this.jwt) {
      this.jwt = new Jwt()
      this.jwt.setJwtStrategy(new DefaultJwtStrategy())
    }

    if (token) {
      this.jwt.set(token)
    }
  }
}

export default LoginOtp
