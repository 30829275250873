import { type Route } from 'vue-router'
import { useLogger } from '/~/composables/logger'

const logger = useLogger()

export function checkGuestAccess(toRoute: Route) {
  if (
    !!toRoute.meta?.guestAccess ||
    (toRoute.matched || []).some((route) => route.meta?.guestAccess)
  ) {
    logger.debug('Guest access allowed')
    return true
  }
  return false
}
