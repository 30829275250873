import { reactive } from 'vue'
import { BatchOrderLoader, BatchOrderLoaderParams } from './BatchOrderLoader'
import { BatchOrderPagination } from './BatchOrderPagination'
import { BatchOrderPayee } from './BatchOrderPayee'
import { BatchOrderPayeeListLocal } from './BatchOrderPayeeListLocal'

export class BatchOrderNewPayees {
  payeeList: BatchOrderPayeeListLocal
  pagination: BatchOrderPagination
  loader: BatchOrderLoader

  constructor(params: BatchOrderLoaderParams) {
    this.payeeList = reactive(new BatchOrderPayeeListLocal())
    this.pagination = reactive(new BatchOrderPagination())
    this.loader = reactive(new BatchOrderLoader(params))
  }

  async load(page: number) {
    const { pagination, items } = (await this.loader.load(page)) as {
      pagination: BatchOrderPagination
      items: Array<BatchOrderPayee>
    }

    this.pagination.setItems(pagination)
    this.payeeList.setItems(items)
  }
}
