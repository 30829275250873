import api from '/~/core/api'
import { useUser } from '/~/composables/user'

async function sendUserEvent(payload) {
  const { user } = useUser()

  if (!user.value.authorized) {
    return
  }

  try {
    const response = await api.post('/v3/user-events', payload)

    return response.data
  } catch (error) {
    console.error(error)

    throw error
  }
}

function eStoreProductVisited(product) {
  return sendUserEvent({
    name: 'eStoreProductVisitedEvent',
    key: '',
    value: '',
    payload: product,
  })
}

function privacyPolicyVisited(payload) {
  return sendUserEvent({
    name: 'PrivacyPolicyVisitedEvent',
    payload,
    key: '',
    value: '',
  })
}

function faqVisited(payload) {
  return sendUserEvent({
    name: 'FAQsVisitedEvent',
    key: '',
    value: '',
    payload,
  })
}

function helpVisitedEvent(payload) {
  return sendUserEvent({
    name: 'HelpVisitedEvent',
    key: '',
    value: '',
    payload,
  })
}

function termsAndConditionsVisited(payload) {
  return sendUserEvent({
    name: 'TermsAndConditionsVisitedEvent',
    key: '',
    value: '',
    payload,
  })
}

function threeDsFaqVisited(payload) {
  return sendUserEvent({
    name: 'ThreeDsFaqVisitedEvent',
    key: '',
    value: '',
    payload,
  })
}

function bannerClicked(payload) {
  return sendUserEvent({
    name: 'BannerClickedEvent',
    key: payload.name || '',
    value: payload.label || '',
    payload,
  })
}

export function useUserEvents() {
  return {
    sendUserEvent,
    eStoreProductVisited,
    bannerClicked,
    faqVisited,
    termsAndConditionsVisited,
    threeDsFaqVisited,
    helpVisitedEvent,
    privacyPolicyVisited,
  }
}
