export const datePickerFormats = {
  daymonth: 'D MMM',
  daymonthyear: 'D MMM YYYY',
  daymonthyearnumeric: 'DD/MM/YYYY',
  daymonthyeartime: 'D MMM YYYY HH:mm a',
  daymonthyearlong: 'D MMMM YYYY',
  daymonthyearlongtime: 'D MMMM YYYY HH:mm a',
  daymonthyearfulltime: 'D MMM YYYY HH:mm:ss',
  daymonthyearlongfulltime: 'D MMMM YYYY HH:mm:ss',
}
