import { AmplitudeAnalyticsTool } from './amplitude-analytics'
import { initAnalyticsDirective } from './analytics-directive'

export * from './base-analytics'
export * from './amplitude-analytics'

const Analytics = new AmplitudeAnalyticsTool()

initAnalyticsDirective(Analytics)

export default Analytics
