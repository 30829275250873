export const QR_SCAN_ROUTE = 'qr-scan'
export const HOME_ROUTE = 'home'
export const AUTH_LOGIN_ROUTE = 'auth-login'
export const AUTH_REQUEST_CODE_ROUTE = 'request-code'
export const AUTH_VERIFY_ACCOUNT_ROUTE = 'verify-account'

export const AUTH_2FA_ROUTES = [
  AUTH_REQUEST_CODE_ROUTE,
  AUTH_VERIFY_ACCOUNT_ROUTE,
]
