<script setup lang="ts">
import { isMissingImport } from '/~/utils/lazy'
import { useProvider } from '/~/composables/provider'

const { generalPhone } = useProvider()

function reloadPage() {
  location.reload()
}
</script>

<template>
  <div
    class="mx-auto flex h-full w-full flex-col items-center justify-center p-8 text-center"
  >
    <template v-if="isMissingImport">
      <h1 class="text-xl leading-8 [text-wrap:balance]">
        Our platform has recently been updated to improve your user experience.
        To enjoy these exciting improvements, please
        <button
          class="font-bold underline underline-offset-4 hover:text-primary"
          @click="reloadPage"
        >
          refresh
        </button>
        the page.
      </h1>

      <p class="mt-8 text-lg">
        If you face any difficulties, please contact us at {{ generalPhone }}.
      </p>
    </template>

    <template v-else>
      <h1 class="text-xl">
        Something went wrong while attempting to display this page.
      </h1>

      <p class="mt-8 text-lg [text-wrap:balance]">
        Try
        <button
          class="font-bold underline underline-offset-4 hover:text-primary"
          @click="reloadPage"
        >
          reloading
        </button>
        the page. If you continue to face difficulties, please contact us at
        {{ generalPhone }}.
      </p>
    </template>
  </div>
</template>
