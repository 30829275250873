import { UserRawData } from '/~/types/api/user'
import Storage from '/~/utils/localStorage'
import { EonxUser } from '/~/composables/user'

export class ThirdPartyUserStorage {
  protected key = 'third-party-user'

  put(user?: EonxUser) {
    if (user) {
      Storage.set(this.key, JSON.stringify(user.raw))
    } else {
      Storage.remove(this.key)
    }
  }

  restore() {
    return new EonxUser(Storage.get(this.key) as UserRawData)
  }

  clear() {
    Storage.remove(this.key)
  }
}
