export class Saving {
  constructor(raw = {}) {
    this.raw = raw
  }

  get name() {
    return this.raw.name
  }

  get slug() {
    return this.raw.slug
  }

  get saving() {
    return +this.raw.savings
  }
}
