import Vue from 'vue'

export async function renderMaintenance() {
  const app = await import('/~/templates/maintenance/app.vue')

  window.eonx.app = new Vue({
    el: '#app',
    render: (h) => h(app.default),
  })
}
