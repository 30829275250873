export type BatchOrderType = 'business' | 'individual'

export class BatchOrder {
  fees = 0
  fileUploadId = ''
  fileName = 'sample.aba'
  number = ''
  reference = ''
  status = ''
  paymentMethods = []
  pointsTransactions = []
  createdAt = ''
  type: BatchOrderType = 'business'
  _initialAmount = 0
  _subtotal = 0
  _total = 0
  transactionsStatusCount: Array<{ status: string; count: number }> = []
  statusTracker: Array<{ tracker: string; status: string; updatedAt: string }> =
    []

  constructor(raw = {}) {
    Object.assign(this, raw)
  }

  get initialAmount() {
    return this._initialAmount
  }

  set initialAmount(value) {
    this._initialAmount = +value
  }

  get subtotal() {
    return this._subtotal
  }

  set subtotal(value) {
    this._subtotal = +value
  }

  get total() {
    return this._total
  }

  set total(value) {
    this._total = +value
  }

  get flowType() {
    return this.type === 'individual' ? 'payroll' : 'bank-file'
  }

  get isStatusEmpty() {
    return !this.status
  }

  get isStatusBatchOrderPending() {
    return ['new', 'file_processing', 'payees_syncing'].includes(this.status)
  }

  get isStatusFileProcessed() {
    return ['file_processed'].includes(this.status)
  }

  get isStatusFileProcessing() {
    return ['new'].includes(this.status)
  }

  get isStatusPayeesSyncing() {
    return ['payees_syncing'].includes(this.status)
  }

  get isStatusPayeePending() {
    return ['payee_pending'].includes(this.status)
  }

  get isStatusPayeeNotSupported() {
    return ['payee_not_supported'].includes(this.status)
  }

  get isStatusPayeeNotFound() {
    return ['payee_not_found'].includes(this.status)
  }

  get isStatusPayeesSynced() {
    return ['payees_synced'].includes(this.status)
  }

  get statusMessage() {
    if (this.isStatusEmpty) {
      return 'Validating file'
    } else if (this.isStatusFileProcessing) {
      return 'Preparing batch'
    } else if (this.isStatusFileProcessed) {
      return 'Verifying payees'
    } else if (this.isStatusPayeesSyncing) {
      return 'Verifying payees'
    } else if (this.isStatusPayeePending) {
      return 'Processing payees'
    } else if (this.isStatusPayeeNotSupported) {
      return 'Payee not supported'
    } else if (this.isStatusPayeeNotFound) {
      return 'Verifying payees'
    }
  }
}

export default BatchOrder
