import { api } from '/~/core'

export class ThirdPartyUserJwtStrategy implements IJwtStrategy {
  promise?: null | Promise<any>
  defaultStrategyJwtToken: string
  ownerExternalId: string

  constructor(defaultStrategyJwtToken: string, ownerExternalId: string) {
    this.ownerExternalId = ownerExternalId
    this.defaultStrategyJwtToken = defaultStrategyJwtToken
  }

  async generateToken() {
    if (this.promise) {
      await this.promise
    }

    this.promise = api.post(
      `/v3/third-party-users/${this.ownerExternalId}/access-token`,
      {},
      {
        token: this.defaultStrategyJwtToken,
      }
    )

    try {
      const response = await this.promise

      return response.data?.token as string
    } catch (error) {
      console.error('Unable to generate JWT token', error)
      throw error
    } finally {
      this.promise = null
    }
  }
}
