export class BatchOrderPagination {
  currentPage = 0
  totalItems = 0
  totalPages = 0
  itemsPerPage = 0

  setItems(pagination: BatchOrderPagination) {
    this.currentPage = pagination.currentPage
    this.totalItems = pagination.totalItems
    this.totalPages = pagination.totalPages
    this.itemsPerPage = pagination.itemsPerPage
  }

  get hasNext() {
    return this.currentPage < this.totalPages
  }

  get nextPage() {
    return this.currentPage + 1
  }
}
