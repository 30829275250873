import { defineComponent } from 'vue'
import ButtonDefault from './default/button-default.vue'
import ButtonIcon from './icon/button-icon.vue'
import ButtonTransition from './transition/button-transition.vue'

export default defineComponent({
  name: 'base-button',
  functional: true,
  render(h, ctx) {
    const { icon, look } = ctx.props

    let component = ButtonDefault

    if (icon) component = ButtonIcon
    else if (look === 'transition') component = ButtonTransition
    return h(component, ctx.data, ctx.children)
  },
})
