import api from '/~/core/api'

export type BatchOrderLoaderParams = {
  url?: string
  perPage?: string
  statuses?: string[]
}

export class BatchOrderLoader {
  params: BatchOrderLoaderParams
  loading = false

  constructor(params: BatchOrderLoaderParams) {
    this.params = params
  }

  setUrl(url: string) {
    this.params.url = url
  }

  setPerPage(perPage: string) {
    this.params.perPage = perPage
  }

  setStatuses(statuses: string[]) {
    this.params.statuses = statuses
  }

  async load(page?: number) {
    const query = new URLSearchParams()

    query.append('page', page?.toString() || '1')
    query.append('perPage', this.params.perPage || '10')

    if (this.params.statuses) {
      this.params.statuses.forEach((status) => {
        query.append('statuses[]', status)
      })
    }
    const queryString = query.toString()

    try {
      this.loading = true
      const response = await api.get(`${this.params.url}?${queryString}`)

      return response.data
    } catch (error) {
      console.error(error)
    } finally {
      this.loading = false
    }
  }
}
