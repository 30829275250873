import { Payee } from '/~/types/api'
import api from '/~/core/api'
import { FlowType } from '/~/composables/checkout/checkout-types'
import PaymentCheckout from './PaymentCheckout'

export class PayeeCheckout extends PaymentCheckout {
  flowType = FlowType.payment
  payee: Payee | null = null
  reference = ''
  getOrderURL = '/v3/payment-orders'
  previewURL = '/v3/payment-orders/checkout/preview'

  get checkoutURL() {
    return '/v3/payment-orders/checkout'
  }

  get isReadyForPayment() {
    return Boolean(this.payee && this.reference)
  }

  get payeeId() {
    return this.payee?.id ?? null
  }

  get termsShouldBeAccepted() {
    return !!this.payWith.bankAccount
  }

  get rootRoute() {
    return {
      name: 'payments-select-payee',
      params: {
        type: 'other',
      },
    }
  }

  async initPayment(payment: any) {
    super.initPayment(payment)

    this.payee = payment.payee
    this.reference = payment.reference
    this.amount = payment.amount
    this.date = payment.date
    this.description = payment.description

    await this.getProgramFees()
  }

  reset() {
    super.reset()

    this.payee = null
    this.reference = ''
  }

  async getOrder(orderNumber: string) {
    this.confirmation.loading = true

    try {
      const { data } = await api.get<any>(`/v3/payment-orders/${orderNumber}`)

      this.confirmation.order = data

      return data
    } catch (error: any) {
      console.error((error.data && error.data.message) || error)
      throw error
    } finally {
      this.confirmation.loading = false
    }
  }
}

export default PayeeCheckout
