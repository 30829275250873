import { roundFigure } from '/~/utils/format'

export function getTotalFeesForSource(
  sources: {
    paymentMethodId: string
    fee?: string
    percentageFeeRate?: string
  }[],
  id?: string
) {
  if (!id) {
    return 0
  }

  const filteredSources = sources.filter((item) => item.paymentMethodId === id)

  return roundFigure(
    filteredSources.reduce(
      (total, source) => total + Number(source?.fee ?? 0),
      0
    )
  )
}
