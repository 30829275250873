const LOG_LEVELS = ['debug', 'info', 'warn', 'error'] as const

export type LogLevel = (typeof LOG_LEVELS)[number]

class Logger {
  private moduleName?: string
  private logLevel: LogLevel

  constructor(moduleName?: string, logLevel: LogLevel = 'info') {
    this.logLevel = logLevel
    this.moduleName = moduleName
  }

  private shouldLog(level: LogLevel): boolean {
    return LOG_LEVELS.indexOf(level) >= LOG_LEVELS.indexOf(this.logLevel)
  }

  private log(level: LogLevel, ...messages: unknown[]): void {
    if (this.shouldLog(level)) {
      const prefix = `${level.toUpperCase()}${
        this.moduleName ? ` [${this.moduleName}]` : ''
      }:`

      console[level](prefix, ...messages)
    }
  }

  public info(...messages: unknown[]): void {
    this.log('info', ...messages)
  }

  public warn(...messages: unknown[]): void {
    this.log('warn', ...messages)
  }

  public error(...messages: unknown[]): void {
    this.log('error', ...messages)
  }

  public debug(...messages: unknown[]): void {
    this.log('debug', ...messages)
  }

  public getLogLevel(): LogLevel {
    return this.logLevel
  }

  public setLogLevel(level: LogLevel): void {
    this.logLevel = level
  }
}

export default Logger
