import { FlowType } from '/~/composables/checkout/checkout-types'
import { Loader, LoaderParams } from '/~/composables/loader'

export class PayIdOrdersManager {
  loader: Loader
  updater: Loader
  orderList: []

  constructor(createParams: LoaderParams, updateParams: LoaderParams) {
    this.loader = new Loader(createParams)
    this.updater = new Loader(updateParams)
    this.orderList = []
  }

  async load() {
    try {
      const { items } = (await this.loader.load()) as {
        items: []
      }

      this.orderList.push(...items)
    } catch (e) {
      console.error(e)
    }
  }

  async cancel(orderNumber: string, flowType: FlowType) {
    try {
      const order = await this.updater.update(
        {
          status: 'cancelled',
        },
        `${this.updater.params[flowType]}/${orderNumber}`
      )

      return order
    } catch (e) {
      console.error(e)
    }
  }
}
