<script>
import BaseLoader from '/~/components/base/loader/base-loader.vue'

export default {
  components: {
    BaseLoader,
  },
  page: {
    title: 'Loading page...',
  },
}
</script>

<template>
  <div class="flex w-full items-center justify-center p-32">
    <base-loader size="xl" />
  </div>
</template>
