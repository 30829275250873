export const businessNumber = {
  mask: '000000000aa0000',
  placeholder: '123456789RP0001',
  length: 15,
  unmaskedLength: 15,
}

export const postcode = {
  mask: '### ###',
  definitions: {
    '#': /[a-zA-Z0-9]/, // alphanumeric
  },
  lazy: true,
}
