export const States = [
  {
    id: 'ACT',
    label: 'Australian Capital Territory',
    placeId: 'ChIJSxCboN9MFmsRA3huXDhEWOc',
  },
  {
    id: 'NSW',
    label: 'New South Wales',
    placeId: 'ChIJDUte93TLDWsRLZ_EIhGvgBc',
  },
  {
    id: 'NT',
    label: 'Northern Territory',
    placeId: 'ChIJDxnz5sJyUSsRdScAAAAAAAA',
  },
  {
    id: 'QLD',
    label: 'Queensland',
    placeId: 'ChIJ_dxieiTf1GsRmb4SdiLQ8vU',
  },
  {
    id: 'SA',
    label: 'South Australia',
    placeId: 'ChIJ88foW55Yp2oR2ND6PZl5fts',
  },
  {
    id: 'TAS',
    label: 'Tasmania',
    placeId: 'ChIJz_o0fifteqoRZEBAKd2ljyo',
  },
  {
    id: 'VIC',
    label: 'Victoria',
    placeId: 'ChIJT5UYfksx1GoRNJWCvuL8Tlo',
  },
  {
    id: 'WA',
    label: 'Western Australia',
    placeId: 'ChIJ0YTziS4qOSoRmaMAMt9KDm4',
  },
]
