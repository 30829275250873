import { reactive, readonly } from 'vue'
import { lazyBottomSheet } from '/~/utils/lazy'
import bottomSheets from '/~/modals/bottom-sheets'

type BottomSheetStyle = {
  centered?: boolean
  fullheight?: boolean
  animation?: 'bottom' | 'right'
}

type BottomSheetOptions = {
  props: Record<string, unknown>
  to: string
  closable: boolean
  style: BottomSheetStyle
  on: Record<string, (...args: never[]) => unknown>
  analytics?: {
    confirm?: Record<string, unknown>
    cancel?: Record<string, unknown>
  }
}

export type BottomSheet = BottomSheetOptions & {
  name: string
  component: unknown
}

type BottomSheets = {
  [key: string]: () => Promise<unknown>
}

class BottomSheetController {
  opened: BottomSheet[] = []
  registered: BottomSheets = {}

  get hasOpenedModals(): boolean {
    return this.opened.length > 0
  }

  get active(): BottomSheet | undefined {
    return this.opened[this.opened.length - 1]
  }

  async show(
    name: string,
    options: Partial<BottomSheetOptions> & {
      uniq?: boolean
    } = {}
  ): Promise<void> {
    const source = this.registered[name]
    const isOpen = this.opened.some((i) => i.name === name)

    if (isOpen && options.uniq) {
      console.warn(`Bottom sheet ${name} is already open`)
      return
    }

    if (source) {
      this.opened.push({
        name,
        component: source,
        to: options.to ?? 'modals-portal',
        closable: options.closable ?? true,
        props: options.props ?? {},
        style: options.style ?? {},
        on: options.on ?? {},
      })
    } else {
      console.warn(`There is no such bottom sheet: ${name}`)
    }
  }

  hide(name?: string): void {
    let idx

    if (name) {
      idx = this.opened.findIndex((i) => i.name === name)
    } else {
      idx = this.opened.length - 1
    }

    if (idx >= 0) {
      this.opened.splice(idx, 1)
    }
  }

  hideAll(): void {
    this.opened.splice(0)
  }

  preload(names: string[] = []): void {
    names.forEach((name) => {
      const modalFunc = this.registered[name]

      if (modalFunc) modalFunc()
    })
  }

  register(modals: BottomSheets = {}): void {
    Object.keys(modals).forEach((key) => {
      this.registered[key] = lazyBottomSheet(modals[key])
    })
  }
}

const bottomSheet = readonly(reactive(new BottomSheetController()))

bottomSheet.register(bottomSheets)

export default bottomSheet
