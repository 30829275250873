import * as amplitude from '@amplitude/analytics-browser'
import debounce from 'lodash-es/debounce'
import {
  BaseAnalyticsTool,
  IAnalyticsEvent,
  IEventParams,
} from './base-analytics'

function maskEmail(email: string) {
  const [localPart, domain] = email.split('@')

  return `${localPart.substring(0, 2)}***@${domain}`
}

function maskId(id: string): string {
  const visiblePart = id.slice(-8) // last 8 characters

  return `***${visiblePart}`
}

export class AmplitudeAnalyticsTool extends BaseAnalyticsTool {
  public initiated = false
  apiKey = ''
  lastSentEvent = ''

  initiate(apiKey = ''): void {
    if (!apiKey) {
      console.warn('API key was not provided, cannot initiate Amplitude')
      return
    }

    try {
      amplitude.init(apiKey, {
        defaultTracking: {
          attribution: true,
          sessions: true,
          formInteractions: false,
          fileDownloads: false,
          pageViews: false,
        },
      })

      this.apiKey = apiKey
      this.initiated = true
    } catch (error) {
      console.warn('amplitude failed to initiate', error)
    }
  }

  sendEvent(event: IAnalyticsEvent, params: IEventParams = {}): void {
    if (!this.initiated) return

    const label = this.combineEventLabel(event)

    if (this.lastSentEvent === label) return

    if (params.debounce) {
      debounce(() => this.trackAmplitudeEvent(label, event), 2000)()
    } else {
      this.trackAmplitudeEvent(label, event)
    }

    this.lastSentEvent = label
  }

  trackAmplitudeEvent(label: string, event: IAnalyticsEvent) {
    amplitude.track(label, event)
  }

  combineEventLabel(event: IAnalyticsEvent) {
    const cta = event.cta || ''
    const label = event.cta ? `Click on ${cta}` : event.label || ''
    const pageGroup = event.pageGroup || ''
    const page = event.page || ''
    const component1 = event.component1 || ''
    const component2 = event.component2 || ''
    const component3 = event.component3 || ''

    return [pageGroup, page, component1, component2, component3, label]
      .filter(Boolean)
      .join(' / ')
  }

  setUser(id: string): void {
    if (!this.initiated) return

    try {
      const identifyEvent = new amplitude.Identify()

      if (id) {
        identifyEvent.set('id', maskId(id)) // Ensure maskId properly obfuscates the ID
      }
      amplitude.identify(identifyEvent)
    } catch (error) {
      console.error('Error setting user properties in Amplitude:', error)
    }
  }
}
