export async function getObjectHash(obj = {}) {
  try {
    const encoder = new TextEncoder()
    const data = encoder.encode(JSON.stringify(obj))

    const buf = await window.crypto.subtle.digest('SHA-256', data)

    return Array.prototype.map
      .call(new Uint8Array(buf), (x) => ('00' + x.toString(16)).slice(-2))
      .join('')
  } catch (err) {
    console.error(err)
    return 'default'
  }
}
