import { StatementAccountRawData } from '/~/types/api'
import Address from '/~/composables/addresses/core/Address'
import StatementAutoPayment from '/~/composables/statements/core/StatementAutoPayment'

export class StatementAccount {
  id = ''
  isPrimary = false
  name = ''
  number = ''
  postcode = ''
  autoPayment = {} as StatementAutoPayment
  nextDueStatementOrders: any[] = [] // The type can be refined further based on the structure
  address: Address | null = null
  state = ''
  verificationStatus = ''
  statementOrders: any[] = []

  constructor(raw: Partial<StatementAccountRawData> = {}) {
    Object.keys(raw).forEach((key) => {
      if (key === 'address') {
        this.address = raw[key] ? new Address(raw[key]) : null
      } else {
        this[key] = raw[key] ?? this[key]
      }
    })
  }

  get isDueToday() {
    return this.state === 'due_today'
  }

  get isDue() {
    return this.state === 'due'
  }

  get isDueSoon() {
    return this.state === 'due_soon'
  }

  get isOverdue() {
    return this.state === 'overdue'
  }

  get isNotRequired() {
    return this.state === 'not_required'
  }

  get isProcessing() {
    return this.state === 'processing'
  }

  get isSettled() {
    return this.state === 'settled'
  }

  get isEmpty() {
    return this.state === 'empty'
  }

  get isElapsed() {
    return this.state === 'lapsed'
  }

  get isNotResolved() {
    return this.state === 'not_resolved'
  }

  get isShowData() {
    return (
      !this.isElapsed &&
      !this.isNotRequired &&
      !this.isNotResolved &&
      !this.isEmpty
    )
  }

  get canRemove() {
    return (
      this.isEmpty || this.isSettled || this.isElapsed || this.isNotRequired
    )
  }

  get isVerifyPending() {
    return this.verificationStatus === 'pending'
  }

  get isVerifyApproved() {
    return this.verificationStatus === 'approved'
  }

  get isVerifyRejected() {
    return this.verificationStatus === 'rejected'
  }

  get isVerifyDeleted() {
    return this.verificationStatus === 'deleted'
  }

  get isDirectDebitDisabled() {
    return (this.isVerifyRejected && this.isEmpty) || this.isNotResolved
  }

  get nextDueStatementOrder() {
    return this.nextDueStatementOrders[0] ?? {}
  }

  set nextDueStatementOrder(statement) {
    this.nextDueStatementOrders.splice(
      0,
      this.nextDueStatementOrders.length,
      statement
    )
  }
}

export default StatementAccount
