import { ref } from 'vue'
import api from '/~/core/api'
import bottomSheet from '/~/core/bottom-sheet'
import emitter from '/~/core/emitter'

const verifying = ref(false)

async function verifyCard({
  amount,
  cardId,
  payeeId,
  multipleSources,
  modalsConfig,
}) {
  verifying.value = true

  try {
    const response = await api.post(
      `/v3/payment-methods/${cardId}/security-requests/mastercard-v1`,
      {
        amount: `${amount}`,
        payeeId,
        multipleSources,
      },
      { notify: false }
    )
    const { startUrl } = response?.data ?? {}

    if (!startUrl) {
      throw new Error('No startup url')
    }

    return new Promise((resolve, reject) => {
      const { mobile = false, to } = modalsConfig
      const modal = {
        name: 'nab-verification',
        props: {
          startUrl,
        },
        on: {
          done: resolve,
          cancel() {
            reject(new Error('Card verification cancelled'))
          },
          fail(error) {
            if (/Failed to verify credit card/.test(error.message)) {
              reject(
                new Error(
                  '3D Secure verification failed. Please contact your bank.'
                )
              )
            } else {
              reject(error)
            }
          },
        },
      }

      if (mobile) {
        bottomSheet.show('nab-verification', { to, closable: false, ...modal })
      } else {
        emitter.emit('modal:show', modal)
      }
    })
  } finally {
    verifying.value = false
  }
}

export const useNab = () => ({
  verifyingCard: verifying,
  verifyCard,
})
