import dayjs from 'dayjs/esm'
import { StatementOrderRawData } from '/~/types/api'
import { formatDate } from '/~/utils/format/date'
import { FlowType } from '/~/composables/checkout/checkout-types'
import {
  StatementAutoPayment,
  StatementAccount,
} from '/~/composables/statements/'
import { useUser } from '/~/composables/user'

export class Statement {
  raw: StatementOrderRawData
  isSelected = false
  autoPayment: StatementAutoPayment = new StatementAutoPayment()
  orderType = FlowType.statement
  statementAccount: StatementAccount

  constructor(raw: StatementOrderRawData) {
    if (raw.payee) {
      raw.payee.name = raw.payee.name ?? 'EonX'
    }
    this.raw = raw || {}
    this.statementAccount = new StatementAccount(raw.userStatementAccount)
  }

  get active() {
    return this.raw.active
  }

  get id() {
    return this.raw.number
  }

  get payee() {
    return this.raw.payee
  }

  get payeeName() {
    return this.raw.metadata?.originalPayeeName || this.raw.payee?.name
  }

  get payeeId() {
    return this.raw.payee?.id
  }

  get canSeeDetails() {
    return !!this.subtotal
  }

  get status() {
    return this.raw.status
  }

  get scheduledAt() {
    return this.raw.scheduledAt
  }

  get dueDate() {
    return this.raw.dueDate
  }

  get createdAt() {
    return this.raw.createdAt
  }

  get statementDate() {
    return this.raw.statementDate
  }

  get statementDateMonthName() {
    return this.statementDate ? formatDate('monthlong', this.statementDate) : ''
  }

  get dueDateMonthName() {
    return this.dueDate ? formatDate('monthlong', this.dueDate) : ''
  }

  get processingDueDate() {
    return this.raw.processingDueDate
  }

  get processingDueDateUTC() {
    return this.raw.processingDueDate
      ? dayjs(this.raw.processingDueDate).utc()
      : null
  }

  get blockProcessingDueDateUTC() {
    return this.raw.blockProcessingDueDate
      ? dayjs(this.raw.blockProcessingDueDate).utc()
      : null
  }

  get blockedPaymentDate() {
    return this.raw.blockedPaymentDate
  }

  get completedAt() {
    return this.raw.completedAt
  }

  get paidAt() {
    return this.raw.paidAt
  }

  get discount() {
    return +this.raw.discount
  }

  get statementTotal() {
    return +this.raw.statementTotal
  }

  get subtotal() {
    return +this.raw.subtotal
  }

  get total() {
    return this.raw.total ? +this.raw.total : undefined
  }

  get paymentMethods() {
    return this.raw.paymentMethods ?? []
  }

  get scheduledPaymentMethods() {
    return this.raw.scheduledPaymentMethods ?? []
  }

  get fees() {
    return this.raw.fees?.items ?? []
  }

  get programFees() {
    const fees = this.fees

    return Number(fees.find((i) => i.label === 'Program Fee')?.amount ?? 0)
  }

  get transactionFees() {
    const fees = this.fees

    return Number(fees.find((i) => i.label === 'Processing Fee')?.amount ?? 0)
  }

  get combinedFees() {
    return this.programFees + this.transactionFees
  }

  get totalFees() {
    const fees = this.fees

    return fees.reduce((sum, i) => sum + Number(i.amount ?? 0), 0)
  }

  get clientNumber() {
    const { user } = useUser()

    return this.raw.userStatementAccount?.number ?? user.value.username ?? ''
  }

  get client() {
    return this.raw.userStatementAccount
  }

  get isCompleted() {
    return this.status === 'completed'
  }

  get isPending() {
    return this.status === 'pending'
  }

  get isScheduled() {
    return this.status === 'scheduled'
  }

  get isIncomplete() {
    return this.status === 'incomplete'
  }

  get isFailed() {
    return ['cancelled', 'failed'].includes(this.status)
  }

  get isNew() {
    return this.status === 'new'
  }

  get isPayNowAvailable() {
    if (!this.processingDueDateUTC) {
      return false
    }

    return dayjs().month() === this.processingDueDateUTC.month()
  }

  get isDueInAWeek() {
    return dayjs(this.processingDueDate).isBetween(
      dayjs(),
      dayjs().add(1, 'week')
    )
  }

  get isBlocked() {
    return (
      this.active &&
      Boolean(this.blockedPaymentDate) &&
      dayjs().diff(dayjs(this.blockedPaymentDate)) >= 0
    )
  }

  get dueInDays() {
    const days = dayjs().diff(
      dayjs(this.processingDueDate).hour(0).minute(0).second(0),
      'day'
    )

    return days > 0 ? 0 : Math.abs(days)
  }
}

export default Statement
