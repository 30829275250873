import {
  StatementAutoPaymentRawData,
  PaymentMethod,
  ProgramFees,
} from '/~/types/api'
import api from '/~/core/api'
import { usePaymentMethods } from '/~/composables/payment-methods'
import { Statement } from '/~/composables/statements'

const { paymentMethods } = usePaymentMethods()

export class StatementAutoPayment {
  id = ''
  statementAccountId?: string
  statementAccountNumber?: string
  statement: Statement = {} as Statement
  programFeesData?: ProgramFees
  programFees = 0
  transactionFees = 0
  programFeesPercentage = 0
  transactionFeesPercentage = 0
  isLoadingProgramFees = false
  isLoadingTransactionFees = false
  private _paymentMethods: PaymentMethod[] = []
  isEnabled = false
  termsAndConditions = true
  day = 0

  constructor(
    raw: Partial<
      StatementAutoPaymentRawData & { statementAccountNumber: string }
    > = {}
  ) {
    Object.keys(raw).forEach((key) => {
      this[key] = raw[key] ?? this[key]
    })

    if (this._paymentMethods.length > 0) {
      this.isEnabled = true
    }
  }

  set paymentMethods(methods: PaymentMethod[]) {
    this._paymentMethods = methods ?? []
  }

  get paymentMethods(): PaymentMethod[] {
    return this._paymentMethods.map((method) => {
      return (
        paymentMethods.value.find(
          (paymentMethod: any) => paymentMethod.id === method.id
        ) ?? method
      )
    })
  }

  get firstPaymentMethod() {
    return this.paymentMethods[0] ?? {}
  }

  get isLoadingFees() {
    return this.isLoadingProgramFees || this.isLoadingTransactionFees
  }

  get hasFees() {
    return this.programFees > 0 || this.transactionFees > 0
  }

  get payeeId() {
    return this.statement?.payeeId
  }

  get subtotal() {
    return this.statement?.subtotal ?? 0
  }

  get subtotalWithProgramFees() {
    return this.statement?.subtotal + this.programFees
  }

  get total() {
    return this.statement?.subtotal + this.programFees + this.transactionFees
  }

  toJSON() {
    return {
      id: this.id,
      statementAccountId: this.statementAccountId,
      statementAccountNumber: this.statementAccountNumber,
      paymentMethods: this._paymentMethods,
      isEnabled: this.isEnabled,
      firstPaymentMethod: this.firstPaymentMethod,
      termsAndConditions: this.termsAndConditions,
    }
  }

  async getFees() {
    try {
      await this.getProgramFees()
      await this.getTransactionFees()
    } catch (error) {
      this.programFeesData = undefined
      this.programFees = 0
      this.transactionFees = 0
      this.programFeesPercentage = 0
      this.transactionFeesPercentage = 0

      throw error
    }
  }

  async getTransactionFees() {
    this.transactionFees = 0

    if (!this.firstPaymentMethod.id || this.subtotal === 0) {
      return
    }

    this.isLoadingTransactionFees = true

    const amount = String(this.subtotal + this.programFees)

    try {
      const payload = {
        paymentDestinations: [
          {
            payeeId: this.statement?.payeeId,
            amount,
          },
        ],
        paymentSources: [
          {
            paymentMethodId: this.firstPaymentMethod.id,
            amount,
          },
        ],
      }

      const { data } = await api.post<any>('/v3/payment-methods/fees', payload)

      this.transactionFees = Number(data?.fee ?? 0)
      this.transactionFeesPercentage = Number(
        data?.paymentSources[0]?.percentageFeeRate ?? 0
      )
    } catch (error: any) {
      console.error((error.data && error.data.message) || error)
    } finally {
      this.isLoadingTransactionFees = false
    }
  }

  async getProgramFees() {
    this.programFeesData = undefined
    this.programFees = 0

    if (this.subtotal === 0) {
      return
    }

    this.isLoadingProgramFees = true

    try {
      const { data } = await api.post<ProgramFees>('/v3/program-fees', {
        amount: this.subtotal,
        payeeId: this.statement?.payeeId,
      })

      this.programFeesData = data
      this.programFees = Number(data?.totalFees ?? 0)
      this.programFeesPercentage = Number(data?.fees[0]?.percentage ?? 0)
    } catch (error: any) {
      console.error((error.data && error.data.message) || error)
    } finally {
      this.isLoadingProgramFees = false
    }
  }
}

export default StatementAutoPayment
