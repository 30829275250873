import { reactive, readonly } from 'vue'
import { lazyModal } from '/~/utils/lazy'

type ModalOptions = {
  props: Record<string, unknown>
  on: Record<string, (...args: never[]) => unknown>
}

export type Modal = ModalOptions & {
  name: string
  component: unknown
}

type Modals = {
  [key: string]: () => Promise<unknown>
}

export type ModalShowOptions = Partial<ModalOptions> & {
  uniq?: boolean
}

class ModalController {
  opened: Modal[] = []
  registered: Modals = {}

  get hasOpenedModals(): boolean {
    return this.opened.length > 0
  }

  get active(): Modal | undefined {
    return this.opened[this.opened.length - 1]
  }

  async show(name: string, options: ModalShowOptions = {}): Promise<void> {
    const source = this.registered[name]
    const isOpen = this.opened.some((i) => i.name === name)

    if (isOpen && options.uniq) {
      console.warn(`Modal ${name} is already open`)
      return
    }

    if (source) {
      this.opened.push({
        name,
        component: source,
        props: options.props ?? {},
        on: options.on ?? {},
      })
    } else {
      console.warn(`There is no such modal: ${name}`)
    }
  }

  hide(name?: string): void {
    let idx

    if (name) {
      idx = this.opened.findIndex((i) => i.name === name)
    } else {
      idx = this.opened.length - 1
    }

    if (idx >= 0) {
      this.opened.splice(idx, 1)
    }
  }

  hideAll(): void {
    this.opened.splice(0)
  }

  preload(names: string[] = []): void {
    names.forEach((name) => {
      const modalFunc = this.registered[name]

      if (modalFunc) modalFunc()
    })
  }

  register(modals: Modals = {}): void {
    Object.keys(modals).forEach((key) => {
      this.registered[key] = lazyModal(modals[key])
    })
  }
}

const modal = readonly(reactive(new ModalController()))

export default modal
