interface IPayee {
  id: string
  abn: string
  accountName: string
  name: string
}

export class BatchOrderTransaction {
  id = ''
  accountName = ''
  accountNumber = ''
  name = ''
  _amount = 0
  bsb = ''
  payee: IPayee = { abn: '', accountName: '', id: '', name: '' }
  status = 'new'
  subStatus: string | null = null
  error = ''
  isLoading = false
  reference = ''
  page = 1

  constructor(raw: Partial<BatchOrderTransaction> = {}) {
    if (!raw.payee) delete raw.payee
    Object.assign(this, raw)
  }

  get amount() {
    return this._amount
  }

  set amount(value) {
    this._amount = +value
  }

  get statusPending() {
    return this.subStatus === 'payee_pending'
  }

  get isLongReference() {
    return this.reference.length > 9
  }
}

export default BatchOrderTransaction
