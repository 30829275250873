import { roundFigure } from '/~/utils/format'

export function formatPoints(
  value: number | bigint | Intl.StringNumericLiteral
) {
  const formatter = new Intl.NumberFormat('en-AU', {
    maximumFractionDigits: 0,
  })

  return formatter.format(value || 0)
}

export function calculatePurchasePointsEarned(
  total: number,
  rebatePercentage: number,
  pointsEarnRate: number
) {
  const rebateValue = (total / 100) * rebatePercentage

  return pointsEarnRate !== 0 ? roundFigure(rebateValue / pointsEarnRate) : 0
}

export function calculatePurchaseDollarsForOnePoint(
  rebatePercentage: number,
  pointsEarnRate: number
) {
  const pointsEarned = calculatePurchasePointsEarned(
    1,
    rebatePercentage,
    pointsEarnRate
  )

  return pointsEarned !== 0 ? roundFigure(1 / pointsEarned) : 0
}

export function calculatePaymentPointsEarned(
  total: number,
  pointsEarnRate: number
) {
  return roundFigure(total * pointsEarnRate)
}

export function calculatePaymentDollarsForOnePoint(pointsEarnRate: number) {
  const pointsEarned = calculatePaymentPointsEarned(1, pointsEarnRate)

  return pointsEarned !== 0 ? roundFigure(1 / pointsEarned) : 0
}
