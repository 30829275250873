export class BaseProcessor {
  loaded = false
  processing = false
  hits = []

  constructor(params) {
    this.params = params || {}

    this.sorting = this.params.sorting
  }

  get length() {
    return this.hits.length
  }

  get sortedHits() {
    if (this.sorting instanceof Function) {
      return this.hits.slice().sort(this.sorting)
    }

    return this.hits
  }

  get isReady() {
    return !this.processing
  }

  get isEmpty() {
    return !this.processing && this.length === 0
  }

  get isLoading() {
    return this.processing && this.length === 0
  }

  get isRefreshing() {
    return this.loaded && this.processing
  }

  get allRecordsLoaded() {
    return this.loaded && (this.total === 0 || this.total === this.length)
  }
}
