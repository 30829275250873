import { useLocalization } from '/~/composables/localization'
import { ensureNumber } from './numeric'

const localization = useLocalization()

export function formatDollar(
  value: string | number | null | undefined,
  emptyValue?: string | number
): string {
  const parsedValue = ensureNumber(value, emptyValue)

  return localization.formatMoney(parsedValue)
}

export function formatDollarCompact(
  value = 0,
  emptyValue?: string | number
): string {
  const parsedValue = ensureNumber(value, emptyValue)

  return localization.formatMoneyCompact(parsedValue)
}

export function dollarFromCents(
  value = 0,
  emptyValue?: string | number
): string {
  const parsedValue = ensureNumber(value, emptyValue)

  return localization.formatMoney(parsedValue / 100)
}

export function formatAmount(value = 0, emptyValue?: string | number): string {
  const parsedValue = ensureNumber(value, emptyValue)

  return localization.formatNumberFixed(parsedValue)
}

export function formatAmountCompact(
  value = 0,
  emptyValue?: string | number
): string {
  const parsedValue = ensureNumber(value, emptyValue)

  return localization.formatNumberFixedCompact(parsedValue)
}
