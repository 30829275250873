import { computed, ref } from 'vue'
import { BankAccountSchema } from '../../bank-account'
import { businessNumber } from '../mask'

const initiateValues = {
  type: 'bankAccount',
  abn: '',
  name: '',
  bankAccountGroup: {
    bsb: '',
    accountNumber: '',
  },
  accountName: '',
  abnName: '',
  terms: false,
}

const form = ref(initiateValues)

const bankAccountSchema = computed<BankAccountSchema>(() => ({
  fields: [
    {
      type: 'abn',
      key: 'abn',
      label: 'ABN',
      validation: {
        rules: `required|max:${businessNumber.length}|businessNumber`,
      },
      placeholder: businessNumber.placeholder,
      disclaimer: '11 digit Australian Business Number',
      mask: {
        mask: businessNumber.mask,
        lazy: true,
      },
      unmaskedModelValue: true,
      analyticsLabel: 'Payee ABN',
      mode: 'passive',
    },
    {
      type: 'string',
      key: 'name',
      label: 'Payee name',
      disabled: !form.value['abnName'],
      disclaimer: 'The trading name of the payee',
      analyticsLabel: 'Payee name',
      validation: {
        rules: 'required|max:256|min:2',
      },
    },
    {
      type: 'group',
      key: 'bankAccountGroup',
      class: 'sm:grid grid-cols-2 gap-4',
    },
    {
      type: 'string',
      key: 'accountName',
      label: 'Account name',
      validation: {
        rules: 'required|min:2|max:256',
      },
      disabled: !form.value['abnName'],
      disclaimer: 'Name on the bank account if unknown use the payee name',
      analyticsLabel: 'Bank account name',
    },
    {
      type: 'object',
      key: 'payeeMetadata',
      class: 'sm:grid grid-cols-2 gap-4',
    },
  ],
  types: {
    bankAccountGroup: {
      fields: [
        {
          type: 'string',
          key: 'bsb',
          label: 'BSB',
          validation: {
            rules: 'required|bsb',
          },
          placeholder: '000-000',
          disabled: !form.value['abnName'],
          disclaimer: 'Payee’s six digit BSB',
          mask: {
            mask: '000-000',
            lazy: true,
          },
          unmaskedModelValue: true,
          analyticsLabel: 'BSB',
        },
        {
          type: 'string',
          key: 'accountNumber',
          label: 'Account Number',
          validation: {
            rules: 'required|account_number|numeric|min:6|max:9',
          },
          disabled: !form.value['abnName'],
          disclaimer: 'Payee’s bank account number',
          analyticsLabel: 'Account number',
        },
      ],
    },
  },
}))

const bankAccountSchemaEditName: BankAccountSchema = {
  fields: [
    {
      type: 'string',
      key: 'abn',
      label: 'Payee ABN',
      disabled: true,
    },
    {
      type: 'string',
      key: 'name',
      label: 'Payee name',
      validation: {
        rules: 'required|max:256|min:2',
      },
      analyticsLabel: 'Payee name',
    },
    {
      type: 'group',
      key: 'bankAccountGroup',
      class: 'sm:grid grid-cols-2 gap-4',
    },
    {
      type: 'string',
      key: 'accountName',
      label: 'Account name',
      disabled: true,
    },
    {
      type: 'object',
      key: 'metadata',
      class: 'sm:grid grid-cols-2 gap-4',
    },
  ],
  types: {
    bankAccountGroup: {
      fields: [
        {
          type: 'string',
          key: 'bsb',
          label: 'BSB',
          disabled: true,
          mask: {
            mask: '000-000',
            lazy: true,
          },
        },
        {
          type: 'string',
          key: 'accountNumber',
          label: 'Account number',
          disabled: true,
        },
      ],
    },
  },
}

export default {
  initiateValues,
  form,
  bankAccountSchema,
  bankAccountSchemaEditName,
  showFormDescription: true,
}
