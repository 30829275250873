import exchange from '/~/configurator/exchange'

export enum UiEvent {
  Ready = 'ui-ready',
  Close = 'ui-close',
  ViewGiftCard = 'ui-view-gift-card',
  ViewEwalletHome = 'ui-view-ewallet-home',
}

function send(event: UiEvent) {
  exchange.sendToParent(event, null)
  window.ReactNativeWebView?.postMessage(event)
  console.log(event)
}

function sendCloseEvent() {
  send(UiEvent.Close)
}

function sendReadyEvent() {
  send(UiEvent.Ready)
}

function sendViewGiftCardEvent() {
  send(UiEvent.ViewGiftCard)
}

function sendViewEwalletHomeEvent() {
  send(UiEvent.ViewEwalletHome)
}

export function useEwalletSdk() {
  return {
    sendCloseEvent,
    sendReadyEvent,
    sendViewGiftCardEvent,
    sendViewEwalletHomeEvent,
  }
}
