import './styles/main.css'
import './styles/index.scss'
import './styles/override.css'
import './styles/markup.css'
import './styles/transitions.scss'
import './styles/quill-editor.css'
import { ProviderRawData } from '/~/types/api/provider'
import { UserRawData } from '/~/types/api/user'
import type { GetEntityType } from '/~/types/cms'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import modal from '/~/core/mdl'
import { useLogger } from '/~/composables/logger'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'
import modals from '/~/modals'
import { renderMaintenance } from '/~/templates/maintenance'

modal.register(modals)

const logger = useLogger('main')

eonx.fetchCmsConfig = fetchCmsConfig
eonx.cms.getEntity = async function <T>(entity: T): Promise<GetEntityType<T>> {
  if (!eonx.hosts.easycms) {
    console.warn('No EasyCMS URL has been found')
  }

  const url =
    eonx.hosts.easycms +
    `/providers/${eonx.cms.provider}` +
    `/environments/${eonx.env}` +
    `/${entity}.json`

  try {
    return (await api.get(url)) as GetEntityType<T>
  } catch (error: any) {
    if (error?.status === 503) {
      throw new Error('Service is unavailable')
    }
    logger.warn(`Failed to load ${url}`, { error })
  }
}

async function fetchData() {
  await Promise.all([
    (api.get('/provider') as Promise<ProviderRawData>).then(function (
      provider
    ) {
      eonx.provider = provider || {}

      /*
       * Set title for the HTML Document
       */
      if (provider.name) {
        document.title = provider.name

        const appleTitle = document.querySelector(
          '[name="apple-mobile-web-app-title"]'
        ) as HTMLMetaElement

        if (appleTitle) {
          appleTitle.content = provider.name
        }
      }
    }),
    api.get('/provider-config').then(function (config) {
      eonx.config = {
        ...config,
        ...eonx.overrideConfig,
      }
    }),
    api.get('/provider-metadata').then(function (metadata) {
      eonx.metadata = metadata
    }),
  ])

  const response = await api.get<UserRawData>('/v3/user-details')

  eonx.user = response?.data ?? null
}

function setCmsProvider(provider: ProviderRawData['subdomain']) {
  eonx.cms.provider = provider.replace(' ', '-').toLowerCase()
}

async function fetchCmsConfig() {
  if (!eonx.provider) return

  const previousProvider = eonx.cms.provider

  let userProvider

  if (
    eonx.user?.membership &&
    eonx.config?.general?.useMembershipForBrandContent?.enabled
  ) {
    userProvider = `${eonx.provider.subdomain}-${eonx.user.membership.name}`
  }

  setCmsProvider(
    eonx.cms.currentProvider || userProvider || eonx.provider.subdomain
  )

  if (eonx.cms.isFetched && previousProvider === eonx.cms.provider) return

  return Promise.all([
    eonx.cms.getEntity('app/design').then(function (design = {}) {
      const root = document.documentElement

      // TODO: remove colors
      const mergedDesign = {
        ...design,
        ...design.colors,
      }

      Object.keys(mergedDesign).forEach((key) => {
        if (
          key.startsWith('color-') ||
          key.startsWith('font-') ||
          key === 'border-radius'
        ) {
          root.style.setProperty(`--${key}`, mergedDesign[key])
        }
      })

      eonx.cms.design = mergedDesign
    }),
    eonx.cms.getEntity('app/configuration').then(function (config) {
      eonx.cms.config = config
    }),
    eonx.cms.getEntity('app/modals').then(function (modals) {
      eonx.cms.modals = modals
    }),
    eonx.cms.getEntity('app/menus').then(function (menus) {
      eonx.cms.menus = menus
    }),
    eonx.cms.getEntity('app/redirects').then(function (redirects) {
      eonx.cms.redirects = redirects
    }),
    eonx.cms.getEntity('app/content').then(function (content) {
      eonx.cms.content = content
    }),
    eonx.cms.getEntity('app/ewallet').then(function (ewallet) {
      eonx.cms.ewallet = {
        ...eonx.cms.ewallet,
        ...ewallet,
      }
    }),
    eonx.cms.getEntity('app/header').then(function (header) {
      eonx.cms.header = header
    }),
    eonx.cms.getEntity('app/custom-pages').then(function (customPages) {
      eonx.cms.customPages = customPages
    }),
    fetch(`${eonx.hosts.api.v3}/v3/modules`, {
      headers: {
        'X-PROVIDER': eonx.provider.subdomain,
        Accept: 'application/json',
      },
    })
      .then((res) => res.json())
      .then((response) => {
        const modules = [
          ...(response.data || []),
          {
            enable: true,
            id: 'health',
            moduleName: 'health',
          },
        ]

        eonx.modules = modules
      })
      .catch((e) => {
        console.warn('Failed to load modules', e)
      }),
  ]).then(() => {
    eonx.cms.isFetched = true
  })
}

window.eonx = eonx

const forcedEmailAuthConfig = {
  login: {
    type: 'email',
    email: {
      enabled: true,
      landing: {
        enabled: false,
      },
      recaptcha: false,
    },
  },
}

async function main() {
  if (import.meta.env.DEV) {
    // NOTE: in preview mode env file is already created as ./dist/env.js
    if (import.meta.env.VITE_RUN !== 'preview') {
      if (import.meta.env.MODE === 'staging') {
        await import('../env/staging')
      } else {
        await import('../env/dev')
      }
    }

    emitter.emit('main:env-loaded')

    eonx.overrideConfig =
      import.meta.env.VITE_FORCE_EMAIL_AUTH === 'true'
        ? forcedEmailAuthConfig
        : null
  }

  // try to delete the previous service worker
  if ('serviceWorker' in navigator) {
    navigator.serviceWorker.getRegistrations().then(function (registrations) {
      let workersCount = 0

      for (const registration of registrations) {
        registration.unregister()
        workersCount += 1
      }
      if (workersCount > 0) {
        window.location.reload()
      }
    })
  }

  try {
    await fetchData()

    const { setUser } = useUser()
    const { currentTemplate } = useProvider()

    setUser(eonx.user)

    if (eonx.cms.isOpen) {
      await import('./boot/frame')
      await new Promise((resolve) => {
        emitter.once('cms:frame-loaded', resolve)
      })
    } else {
      await eonx.fetchCmsConfig()
    }

    if (eonx.cms.design.template) {
      currentTemplate.value = eonx.cms.design.template
    } else if (eonx.provider.subdomain === 'australiapost') {
      currentTemplate.value = 'australiapost'
    } else if (
      [
        'b2bx',
        'b2baupvt',
        'demo',
        'gorewards',
        'paymentsv3',
        'dentalwallet',
        'symbionelite',
      ].includes(eonx.provider.subdomain)
    ) {
      currentTemplate.value = 'bill-payments'
    } else if (['bupa', 'bupasso'].includes(eonx.provider.subdomain)) {
      currentTemplate.value = 'bupa'
    } else if (eonx.provider.subdomain === 'cashrewards') {
      currentTemplate.value = 'cashrewards'
    } else {
      currentTemplate.value = 'default'
    }

    await import('./app')

    if (eonx.user) {
      emitter.emit('auth:success', eonx.user)
    } else {
      emitter.emit('auth:required')
    }
  } catch (error) {
    console.error(error)

    renderMaintenance()
  }
}
main()
