export const States = [
  {
    id: 'NL',
    label: 'Newfoundland and Labrador',
    placeId: 'ChIJD_AvfcKjDEsRqDl21bOd7GQ',
  },
  {
    id: 'PE',
    label: 'Prince Edward Island',
    placeId: 'ChIJxYN_1N1SXksRhmaS9NXWaKk',
  },
  {
    id: 'NS',
    label: 'Nova Scotia',
    placeId: 'ChIJRc8Y3pgSWUsRQAnP-B5YAgE',
  },
  {
    id: 'NB',
    label: 'New Brunswick',
    placeId: 'ChIJzQ41MtD9o0wRzAVoAlrJbeY',
  },
  {
    id: 'QC',
    label: 'Quebec',
    placeId: 'ChIJk4jbBYqWuEwRAzro8GMtxY8',
  },
  {
    id: 'ON',
    label: 'Ontario',
    placeId: 'ChIJrxNRX7IFzkwRCR5iKVZC-HA',
  },
  {
    id: 'MB',
    label: 'Manitoba',
    placeId: 'ChIJE7XnRW_gbVIRaMfBq_JVDxw',
  },
  {
    id: 'SK',
    label: 'Saskatchewan',
    placeId: 'ChIJL3ZlI7Fx-FIRK-HCklcrNBU',
  },
  {
    id: 'AB',
    label: 'Alberta',
    placeId: 'ChIJtRkkqIKyCVMRno6bQJpHqbA',
  },
  {
    id: 'BC',
    label: 'British Columbia',
    placeId: 'ChIJr2prqsdmelMR-fHnN-lBG4g',
  },
  {
    id: 'YT',
    label: 'Yukon',
    placeId: 'ChIJiYtStJiBF1ER6pbMYdWcFC4',
  },
  {
    id: 'NT',
    label: 'Northwest Territories',
    placeId: 'ChIJDcHTs_Q4EVERjVnGRNguMhk',
  },
  {
    id: 'NU',
    label: 'Nunavut',
    placeId: 'ChIJ7aUkKkkjCE0RtqnxTwVx0_8',
  },
]
