import dayjs from 'dayjs/esm'
import advancedFormat from 'dayjs/esm/plugin/advancedFormat'
import customParseFormat from 'dayjs/esm/plugin/customParseFormat'
import isSameOrAfter from 'dayjs/esm/plugin/isSameOrAfter'
import isSameOrBefore from 'dayjs/esm/plugin/isSameOrBefore'
import isToday from 'dayjs/esm/plugin/isToday'
import isYesterday from 'dayjs/esm/plugin/isYesterday'
import relativeTime from 'dayjs/esm/plugin/relativeTime'
import timezone from 'dayjs/esm/plugin/timezone'
import utc from 'dayjs/esm/plugin/utc'
import { DateType } from '/~/types/date'
import { useLocalization } from '/~/composables/localization'

type DateValue = dayjs.ConfigType

dayjs.extend(utc)
dayjs.extend(advancedFormat)
dayjs.extend(relativeTime)
dayjs.extend(isToday)
dayjs.extend(isYesterday)
dayjs.extend(timezone)
dayjs.extend(isSameOrBefore)
dayjs.extend(isSameOrAfter)
dayjs.extend(customParseFormat)

export function createDate(value?: DateValue, format?: string) {
  return dayjs(value, format)
}

function parseDate(date: DateValue): dayjs.Dayjs | undefined {
  if (!date || (typeof date === 'number' && isNaN(date))) {
    return
  }

  if (!dayjs(date).isValid()) {
    return
  }

  return dayjs(date)
}

function isValidDate(date: DateValue): boolean {
  return parseDate(date) !== undefined
}

function formatDate(type: DateType, date: DateValue): string | undefined {
  const { localizeDate } = useLocalization()

  const dayjsDate = parseDate(date)

  if (dayjsDate === undefined) {
    console.warn('Invalid date value provided:', date)
    return
  }

  return localizeDate(dayjsDate.toDate(), type)
    .replaceAll('-', '/')
    .replace(' at ', ', ')
}

export function isSameDay(firstDate: DateValue, secondDate: DateValue) {
  return (
    isValidDate(firstDate) &&
    isValidDate(secondDate) &&
    formatDate('daymonthyearnumeric', firstDate) ===
      formatDate('daymonthyearnumeric', secondDate)
  )
}

export function isSameMonth(firstDate: DateValue, secondDate: DateValue) {
  return (
    isValidDate(firstDate) &&
    isValidDate(secondDate) &&
    formatDate('monthyear', firstDate) === formatDate('monthyear', secondDate)
  )
}

export function isSameYear(firstDate: DateValue, secondDate: DateValue) {
  return (
    isValidDate(firstDate) &&
    isValidDate(secondDate) &&
    formatDate('year', firstDate) === formatDate('year', secondDate)
  )
}

export function setDefaultTimezone(timezone: string) {
  dayjs.tz.setDefault(timezone)
}

export { formatDate, parseDate, isValidDate }

export default formatDate
