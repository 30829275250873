import { ref, reactive } from 'vue'
import api from '/~/core/api'

const OFFERS_CACHE = {}
const RETAILERS_CACHE = {}

const retailer = ref(null)
const offer = reactive({})
const rebate = ref(null)

function resetRetailer() {
  retailer.value = null
  offer.value = null
}

async function getRetailer(slug) {
  try {
    resetRetailer()
    const { data } = await api.get(`/retailers/${slug}`)

    retailer.value = data

    return [null, data]
  } catch (exception) {
    console.error(exception)
    return [exception.response, null]
  }
}

async function getRetailerOffers({ slug, offer }) {
  try {
    const { data } = await api.get(`/retailers/${slug}/offers/${offer}`)

    offer = data
    return data
  } catch (exception) {
    console.error(exception)
    return null
  }
}

async function getProductRebate({
  retailerExternalId,
  externalOfferId,
  externalProductId,
  variableValue,
}) {
  try {
    const { data } = await api.get(
      `/retailers/${retailerExternalId}/offers/${externalOfferId}/products/${externalProductId}/rebate${
        variableValue ? '?value=' + variableValue : ''
      }`
    )

    rebate.value = data
    return data
  } catch (exception) {
    console.warn(exception)
    return null
  }
}

async function getRetailerOffersCached({ slug }) {
  if (!OFFERS_CACHE[slug]) {
    RETAILERS_CACHE[slug] = RETAILERS_CACHE[slug] || getRetailer(slug)

    const [, retailer] = await RETAILERS_CACHE[slug]

    OFFERS_CACHE[slug] = getRetailerOffers({
      slug,
      offer: retailer?.relationships?.offers?.data?.[0]?.id ?? null,
    })
  }

  return OFFERS_CACHE[slug]
}

async function quickBuy(item) {
  try {
    const { data } = await api.post('/order/quick-buy', item)

    return [null, data]
  } catch (error) {
    console.warn(error)
    return [error]
  }
}

export function useRetailer() {
  return {
    retailer,
    offer,
    rebate,

    getRetailer,
    getRetailerOffers,
    getRetailerOffersCached,
    getProductRebate,
    quickBuy,
  }
}
