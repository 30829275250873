/* eslint-disable import/order */
import { ref } from 'vue'
import api, { jwt } from '/~/core/api'
import { useEwalletSdk } from '/~/composables/ewallet-sdk'
import { useUser } from '/~/composables/user'
import { useProvider } from '/~/composables/provider'
import { useCms } from '/~/composables/cms'
import { useLogger } from '/~/composables/logger'
import OtpFactory from '/~/extensions/otp/composables/core/OtpFactory'
import { ThirdPartyUserStorage } from '/~/composables/third-party-users/core/ThirdPartyUserStorage'

const logger = useLogger('auth')
const { sendCloseEvent } = useEwalletSdk()

const isLoggingOut = ref(false)

async function anotherWayOtp() {
  try {
    OtpFactory.clear('login')
    await jwt.get()
  } catch (err) {
    logger.error(err)
  }
}

async function signIn(payload: any) {
  return api.post('/auth/sign-in', payload)
}

async function signUp(token: string | null, form: any) {
  const { registrationHidePassword } = useProvider()

  const response: any = await api.post(
    token ? `/auth/sign-up?token=${token}` : '/auth/sign-up',
    form,
    { notify: false }
  )

  if (!response?.data) {
    throw new Error(response)
  }

  if (!registrationHidePassword.value) {
    try {
      await jwt.get()
    } catch (err) {
      logger.error(err)
      return
    }
  }

  return response?.data
}

function resetPassword(payload: any) {
  const { providerName } = useProvider()

  const headers = {
    'x-provider': providerName.value,
  }

  const options = {
    headers,
    requireAuth: false,
  }

  return api.post('/v3/passwords', payload, options)
}

function createPassword(token: string, payload: any) {
  const options = {
    requireAuth: false,
  }

  return api.put(`/v3/passwords/${token}`, payload, options)
}

async function logout() {
  const { setUser } = useUser()
  const thirdPartyUserStorage = new ThirdPartyUserStorage()
  const { cmsConfig } = useCms()
  const hasCustomSignoutPage = cmsConfig.value?.['logout-custom-page']
  const signoutRedirectTo = hasCustomSignoutPage ? '/sign-out' : '/logout'

  setUser(null)
  eonx.user = null
  logger.debug('Logging out')
  OtpFactory.clear('login')
  jwt.clear()
  isLoggingOut.value = true
  sendCloseEvent()
  thirdPartyUserStorage.clear()
  try {
    await fetch('/logout', { redirect: 'manual' })
    window.location.replace(signoutRedirectTo)
  } catch (e) {
    console.error(e)
    window.location.replace('/')
  }
}

async function signout(options = { notify: false }) {
  const { setUser } = useUser()

  setUser(null)
  eonx.user = null
  logger.debug('Signing out')
  OtpFactory.clear('login')
  jwt.clear()
  isLoggingOut.value = true
  sendCloseEvent()
  try {
    return await api.post('/auth/sign-out', null, options)
  } catch (e) {
    logger.error(e)
    throw e
  } finally {
    isLoggingOut.value = false
  }
}

export function useAuth() {
  return {
    isLoggingOut,
    anotherWayOtp,
    signIn,
    signUp,
    resetPassword,
    createPassword,
    logout,
    signout,
  }
}
