import { computed, reactive, ref } from 'vue'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import { EntityProcessor } from '/~/core/processors/entity'
import { ensureNumber } from '/~/utils/format'
import {
  calculatePaymentDollarsForOnePoint,
  calculatePaymentPointsEarned,
  calculatePurchaseDollarsForOnePoint,
  calculatePurchasePointsEarned,
  formatPoints,
} from '/~/utils/points'
import { FlowType } from '/~/composables/checkout/checkout-types'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

// TODO: Move to useExtensions TS version when ready
type Manifest = {
  id: string
  label: string
  moduleName: string
  type: string
  meta: {
    MarketplacepaymentEarnRate: number
  }
  config: {
    minimumPointsBalanceToRedeem?: number
    accountExternalIdMapping: {
      source: string
    }
    customBurnRates: {
      action: string
      rate: number
    }[]
    customEarnRates: {
      description: string[]
      label: string
      membershipId: string
      membershipLabel: string
      rate: number
    }[]
    events: {
      enabled: boolean
      event: string
    }[]
    externalEvents: {
      earnEmail: {
        enabled: boolean
        firstEventOnly: boolean
      }
      enabled: boolean
      event: string
      userActivityEnabled: boolean
    }[]
    id: string
    multipleAccounts: {
      enabled: boolean
    }
    paymentBurnRate: number
    paymentEarnRate: number
    pointsExpiry: {
      enabled: true
    }
  }
}

type Points = {
  active: boolean
  availableBalance: number
  badges: any[]
  balance: number
  createdAt: string
  externalId: string
  lifetimeBalance: number
  maxBalance: number
  membership: {
    createdAt: string
    id: string
    updatedAt: string
    finishAt: null | string
    membership: null | string
  }
  metadata: []
  minBalance: number
  providerId: string
  updatedAt: string
}

const { getManifestByName } = useExtensions()

const points = ref<Points>()
const fetching = ref(false)

const state = reactive({
  pointsProcessor: new EntityProcessor({
    entity: 'v3/points/transactions',
  }),
})

const pointsLabel = computed(() => {
  const { providerTitle } = useProvider()

  return `${providerTitle.value} Points`
})
const pointsBalance = computed(() =>
  Math.max(points.value?.availableBalance ?? 0, 0)
)
const formattedPointsBalance = computed(() => formatPoints(pointsBalance.value))
const pointsLoading = computed(() => fetching.value)
const pointsProcessor = computed(() => state.pointsProcessor)

const isPointsEnabled = computed(() =>
  Boolean(
    eonx.modules.find((module: { type: string }) => module.type === 'points')
  )
)

const pointsManifest = computed(
  (): Manifest => getManifestByName('points') ?? {}
)
const pointsConfig = computed(() => pointsManifest.value.config ?? {})

const isPurchaseOrderPoints = computed(
  () =>
    pointsConfig.value.events?.find((item) =>
      item.event.startsWith('purchaseOrderCompleted')
    )?.enabled ?? false
)
const isPaymentOrderPoints = computed(
  () =>
    pointsConfig.value.events?.find((item) =>
      item.event.startsWith('paymentOrderCompleted')
    )?.enabled ?? false
)
const isPaymentOrderCustomEarnRates = computed(
  () =>
    pointsConfig.value.events?.find(
      (item) => item.event === 'paymentOrderCompletedWithCustomEarnRates'
    )?.enabled ?? false
)

type CustomEarnRate = {
  description: string[]
  label: string
  membershipId: string
  membershipLabel: string
  rate: number
}

const defaultEarnRate = computed(() => pointsConfig.value?.paymentEarnRate ?? 0)
const customEarnRates = computed<CustomEarnRate[]>(
  () => pointsConfig.value?.customEarnRates ?? []
)
const rebatePercentage = computed(() => 1)

const userCustomEarnRate = computed(() => {
  const { user } = useUser()

  return (
    customEarnRates.value.find((rate: { membershipLabel: string }) => {
      return rate.membershipLabel === user.value.membershipLabel
    })?.rate ?? defaultEarnRate.value
  )
})

type EarnPointsRateFlow = 'payment' | 'purchase'

const earnPointsRate = computed<{
  [T in EarnPointsRateFlow]: number
}>(() => ({
  purchase: defaultEarnRate.value,
  payment: isPaymentOrderCustomEarnRates.value
    ? userCustomEarnRate.value
    : defaultEarnRate.value,
}))

const dollarsForOnePoint = computed<{
  [T in EarnPointsRateFlow]: number
}>(() => ({
  purchase: calculatePurchaseDollarsForOnePoint(
    rebatePercentage.value,
    earnPointsRate.value.purchase
  ),
  payment: calculatePaymentDollarsForOnePoint(earnPointsRate.value.payment),
}))

function calculatePointsEarnedForType(
  type: EarnPointsRateFlow,
  value: number | string
) {
  const amount = ensureNumber(value)

  switch (type) {
    case 'purchase':
      return calculatePurchasePointsEarned(
        amount,
        rebatePercentage.value,
        earnPointsRate.value.purchase
      )
    case 'payment':
      return calculatePaymentPointsEarned(amount, earnPointsRate.value.payment)
    default:
      return 0
  }
}

function calculatePointsEarnedForPayment(value: number | string) {
  return calculatePointsEarnedForType('payment', value)
}

function calculatePointsEarnedForPurchase<
  T extends { type: string; subTotal: number }
>(items: T[]) {
  return items.reduce((sum, item) => {
    if (item.type === 'estore') {
      return sum
    }

    return sum + calculatePointsEarnedForType('purchase', item.subTotal)
  }, 0)
}

/** Future plan: we can use 'v3/points/calculate' endpoint to calculate the points to be burn  */
const burnPointsRates = computed(
  () => pointsConfig.value?.customBurnRates ?? []
)
const burnPointsRateDefault = computed(
  (): number => pointsConfig.value?.paymentBurnRate ?? 1
)

function getBurnPointsRate(action: string): number {
  const filteredRate = burnPointsRates.value.find(
    (rate: { action: string }) => rate.action === action
  )

  return filteredRate?.rate ?? burnPointsRateDefault.value
}

const burnPointsRateOrder = computed(() => getBurnPointsRate(FlowType.purchase))
const burnPointsRateProgramOrder = computed(() =>
  getBurnPointsRate(FlowType.pointsProgramOrder)
)
const burnPointsRatePaymentOrder = computed(() =>
  getBurnPointsRate(FlowType.payment)
)
const burnPointsRateStatementOrder = computed(() =>
  getBurnPointsRate(FlowType.statement)
)
const burnPointsRateBatchOrder = computed(() =>
  getBurnPointsRate(FlowType.batch)
)

const minimumPointsBalanceToRedeem = computed(
  (): number => pointsConfig.value.minimumPointsBalanceToRedeem ?? 0
)

async function fetchBalance() {
  if (!isPointsEnabled.value) {
    return
  }

  try {
    fetching.value = true
    const { data } = await api.get('/v3/points/balance')

    points.value = data as Points
  } catch (error: any) {
    console.error((error.data && error.data.message) || error)
  } finally {
    fetching.value = false
  }
}

const calculatePointsToBurnOrder = (amount: number) => {
  return Math.round(Math.round(amount * 100) / burnPointsRateOrder.value / 100)
}

const calculateAmountFromPointsToBurnOrder = (amount: number) => {
  return amount * burnPointsRateOrder.value
}

emitter.on('notifications:points_balance_updated', fetchBalance)

export const usePoints = () => ({
  getBurnPointsRate,
  pointsLabel,
  pointsBalance,
  formattedPointsBalance,
  pointsLoading,
  pointsProcessor,
  isPointsEnabled,
  pointsConfig,
  isPurchaseOrderPoints,
  isPaymentOrderPoints,
  customEarnRates,
  burnPointsRateOrder,
  burnPointsRateProgramOrder,
  burnPointsRatePaymentOrder,
  burnPointsRateStatementOrder,
  burnPointsRateBatchOrder,
  minimumPointsBalanceToRedeem,
  fetchBalance,
  calculatePointsToBurnOrder,
  calculateAmountFromPointsToBurnOrder,
  calculatePointsEarnedForType,
  calculatePointsEarnedForPurchase,
  calculatePointsEarnedForPayment,
  dollarsForOnePoint,
  earnPointsRate,
})
