import { ref, computed, reactive } from 'vue'
import { Payee } from '/~/types/api'
import api from '/~/core/api'
import { useExtensions } from '/~/composables/extensions'
import { useProvider } from '/~/composables/provider'

export interface NewPayee {
  accountName: string
  name: string
  accountNumber: string
  bsb: string
  abn: string
  type: string
  options?: any
}

interface PayeeConfig {
  page?: number
  perPage?: number
}

const NEW_STATEMENT_ACCOUNT_TYPE = 'new-account'
const BANK_ACCOUNT_TYPE = 'bank-account'
const BPAY_TYPE = 'bpay'

const payees = reactive({
  items: [] as Payee[],
  creating: false,
  pagination: {
    currentPage: 1,
    hasNextPage: false,
    hasPreviousPage: false,
    itemsPerPage: undefined,
    totalItems: undefined,
    totalPages: 1,
  },
})

const hiddenGroupedPayees = ref([])
const isPayeesLoading = ref(false)

const allPayees = computed(() => [
  ...payees.items,
  ...hiddenGroupedPayees.value,
])

const config = computed(() => {
  const { getConfigByName } = useExtensions()

  return getConfigByName('bill-pay')
})

const allowMultiplePayees = computed(() => {
  return config.value?.payee?.allowMultiple ?? true
})

const bankAccountEnabled = computed(
  () => config.value?.bankAccount?.enabled ?? false
)

const bpayEnabled = computed(() => config.value?.bpay?.enabled ?? false)

const showHiddenPayees = computed(
  () =>
    config.value?.bankAccount?.showHiddenPayees?.enabled ??
    bankAccountEnabled.value
)

const bankAccountWhitelistEnabled = computed(
  () => config.value?.bankAccount?.whitelist?.enabled ?? false
)

async function fetchPayees(
  options = { page: 1, perPage: 1000 } as PayeeConfig
) {
  try {
    isPayeesLoading.value = true
    const { data } = await api.get(
      `/v3/payees?perPage=${options.perPage}&page=${options.page}`
    )

    payees.items = (data as any).items || []
    payees.pagination = (data as any).pagination

    if (showHiddenPayees.value) {
      const { data } = await api.get(
        '/v3/payees/hidden-grouped?perPage=1000&page=0'
      )
      const items = (data as any).items ?? []

      hiddenGroupedPayees.value = items
        .filter(
          (item: { activeStatementOrdersCount: number }) =>
            item.activeStatementOrdersCount > 0
        )
        .map((item: { isHiddenGrouped: boolean }) => {
          item.isHiddenGrouped = true

          return item
        })
    }
  } catch (error) {
    console.error(error)
  } finally {
    isPayeesLoading.value = false
  }
}

async function getPayee(id: string) {
  const { data } = await api.get(`/v3/payees/${id}`)

  return data
}

async function getPayeeByWalletReference(ewalletReference: string) {
  const { data } = await api.get('/v3/payees/?perPage=1000&page=0')
  const items = (data as any).items || []
  const payee = items.find(
    (item: { ewalletReference: string }) =>
      item.ewalletReference === ewalletReference
  )

  if (!payee) {
    throw new Error()
  }

  return payee
}

async function createPayee(newPayee: NewPayee) {
  payees.creating = true

  try {
    const { data } = await api.post<Payee>(
      '/v3/payees',
      newPayee,
      newPayee.options
    )

    return data
  } catch (error) {
    console.error(error)
    throw error
  } finally {
    payees.creating = false
  }
}

async function updatePayee(id: string, payload: any) {
  try {
    const { data } = await api.put(`/v3/payees/${id}`, payload)

    const payeeIndex = payees.items.findIndex((p) => p.id === id)

    payees.items.splice(payeeIndex, 1)
    payees.items.unshift(data as any)

    return data
  } catch (error: any) {
    if (error.data) {
      throw error.data
    }

    throw error
  }
}

async function removePayee(id: string) {
  await api.delete(`/v3/payees/${id}`)

  const payeeIndex = payees.items.findIndex((p) => p.id === id)

  payees.items.splice(payeeIndex, 1)
}

async function searchPayee(
  url: string,
  searchParams: { email?: string; mobile?: string }
) {
  try {
    const { data } = await api.post(url, searchParams)

    return data
  } catch (error: any) {
    if (error.data) {
      throw error.data
    }

    throw error
  }
}

async function searchPayeeByEmail(searchQuery: string) {
  return searchPayee('/v3/ewallet/lookup/email', { email: searchQuery })
}

async function searchPayeeByMobile(searchQuery: string) {
  return searchPayee('/v3/ewallet/lookup/mobile', { mobile: searchQuery })
}

async function submitPay(payload: any) {
  try {
    const { data } = await api.post('/v3/payment-orders/checkout', payload)

    return data
  } catch (error: any) {
    throw error.data
  }
}

async function validateBPay(billerCode: string) {
  try {
    const { data } = await api.post(
      '/v3/validation/bpay',
      { billerCode },
      { notify: false }
    )

    return data
  } catch (error: any) {
    throw error.data
  }
}

async function getPayeeFromName(name: string) {
  const { providerName } = useProvider()

  try {
    const { data } = await api.get(
      `/v3/business-number?name=${name}&limit=15`,
      {
        notify: false,
        headers: {
          'x-provider': providerName.value,
        },
      }
    )

    return (data as any).map((i: { entityName: string; abn: string }) => ({
      label: i.entityName,
      value: i.abn,
    }))
  } catch (error) {
    return []
  }
}

async function getPayeeFromNumber(businessNumber: string) {
  const { providerName } = useProvider()

  try {
    const { data } = await api.get(`/v3/business-number/${businessNumber}`, {
      notify: false,
      headers: {
        'x-provider': providerName.value,
      },
    })

    return data
  } catch (error) {
    return null
  }
}

function getPayeeByOptions(options: any) {
  return payees.items.find(
    (item) =>
      item.accountNumber === options.accountNumber &&
      item.bsb === options.bsb &&
      item.abn === options.abn &&
      item.type === options.type
  )
}

export function usePayees() {
  return {
    NEW_STATEMENT_ACCOUNT_TYPE,
    BANK_ACCOUNT_TYPE,
    BPAY_TYPE,

    billPayModuleConfig: config,

    payees,
    allPayees,
    showHiddenPayees,
    hiddenGroupedPayees,
    bankAccountEnabled,
    bpayEnabled,
    allowMultiplePayees,
    isPayeesLoading,
    bankAccountWhitelistEnabled,

    fetchPayees,
    getPayee,
    getPayeeByWalletReference,
    createPayee,
    updatePayee,
    removePayee,
    searchPayeeByEmail,
    searchPayeeByMobile,
    submitPay,
    validateBPay,
    getPayeeFromName,
    getPayeeFromNumber,
    getPayeeByOptions,
  }
}
