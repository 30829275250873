// TODO: think of a way to iterate through a folder so we dont have to do this manually
import Vue, { computed } from 'vue'
import VueI18n, { DateTimeFormat, NumberFormat } from 'vue-i18n'
import { DateType } from '/~/types/date'
import { datePickerFormats as enAUDatePickerFormats } from './localizations/en-AU/datetime'
import * as enAUFormat from './localizations/en-AU/format'
import enAUPayeeBankAccount from './localizations/en-AU/forms/payee-bank-account'
import enAUBankAccount from './localizations/en-AU/forms/payment-bank-account'
import * as enAUMask from './localizations/en-AU/mask'
import enAUMessages from './localizations/en-AU/messages.json'
import * as enAUStates from './localizations/en-AU/states'
import { datePickerFormats as enCADatePickerFormats } from './localizations/en-CA/datetime'
import * as enCAFormat from './localizations/en-CA/format'
import enCAPayeeBankAccount from './localizations/en-CA/forms/payee-bank-account'
import enCABankAccount from './localizations/en-CA/forms/payment-bank-account'
import * as enCAMask from './localizations/en-CA/mask'
import enCAMessages from './localizations/en-CA/messages.json'
import * as enCAStates from './localizations/en-CA/states'

export type * from './localizations/bank-account'

Vue.use(VueI18n)

type CAMessagesType = typeof enCAMessages
type AUMessagesType = typeof enAUMessages
type MessagesType = CAMessagesType & AUMessagesType
type Path<T> = T extends object
  ? {
      [K in keyof T]-?: K extends string | number
        ? T[K] extends object
          ? `${K}.${Path<T[K]>}`
          : `${K}`
        : never
    }[keyof T]
  : never
type Locale = 'en-AU' | 'en-CA'

const messages = {
  'en-AU': enAUMessages,
  'en-CA': enCAMessages,
}

const bankAccount = {
  'en-AU': enAUBankAccount,
  'en-CA': enCABankAccount,
}

const payeeBankAccount = {
  'en-AU': enAUPayeeBankAccount,
  'en-CA': enCAPayeeBankAccount,
}

const commonDateTimeFormats = {
  daymonth: {
    // 4 Dec
    month: 'short',
    day: 'numeric',
  },
  daymonthyear: {
    // 4 Dec 2024
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  },
  daymonthyearlong: {
    // 4 December 2024
    year: 'numeric',
    month: 'long',
    day: 'numeric',
  },
  daymonthyearnumeric: {
    // 12/01/2024
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  },
  daymonthyeartime: {
    // 4 Dec 2024, 10:49 am
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  daymonthyearlongtime: {
    // 4 December 2024, 10:49 am
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  },
  daymonthyearfulltime: {
    // 4 Dec 2024, 15:49:37
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  daymonthyearlongfulltime: {
    // 4 December 2024, 15:49:37
    year: 'numeric',
    month: 'long',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    second: 'numeric',
  },
  monthyear: {
    // Dec 2024
    year: 'numeric',
    month: 'short',
  },
  monthyearnumeric: {
    // 01/2024
    year: 'numeric',
    month: '2-digit',
  },
  monthnumeric: {
    // 01
    month: '2-digit',
  },
  monthshort: {
    // Dec
    month: 'short',
  },
  monthlong: {
    // December
    month: 'long',
  },
  year: {
    // 2024
    year: 'numeric',
  },
  weekdayshort: {
    // Thu
    weekday: 'short',
  },
  weekdaylong: {
    // Saturday
    weekday: 'long',
  },
  day: {
    // 04
    day: '2-digit',
  },
  time: {
    // 11:11 pm
    hours: 'numeric',
    minute: 'numeric',
  },
  time24: {
    // 23:11
    hours: 'numeric',
    minute: 'numeric',
    hour12: false,
  },
} as DateTimeFormat

const dateTimeFormats = {
  'en-AU': commonDateTimeFormats,
  'en-CA': commonDateTimeFormats,
}

export const datePickerCommonFormats = {
  monthyear: 'MMMM YYYY',
  monthyearnumeric: 'MM/YYYY',
  monthnumeric: 'MM',
  monthshort: 'MMM',
  monthlong: 'MMMM',
  year: 'YYYY',
  weekdayshort: 'ddd',
  weekdaylong: 'dddd',
  day: 'DD',
  time: 'HH:mm a',
  time24: 'HH:mm',
}

const datePickerFormats = {
  'en-AU': {
    ...datePickerCommonFormats,
    ...enAUDatePickerFormats,
  },
  'en-CA': {
    ...datePickerCommonFormats,
    ...enCADatePickerFormats,
  },
}

const commonNumberFormats = {
  currency: {
    style: 'currency',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  currencyCompact: {
    style: 'currency',
    notation: 'compact',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  decimal: {
    style: 'decimal',
    useGrouping: true,
    maximumFractionDigits: 2,
  },
  decimalFixed: {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  },
  decimalFixedCompact: {
    style: 'decimal',
    useGrouping: true,
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation: 'compact',
  },
  percent: {
    style: 'percent',
    useGrouping: false,
  },
}

const numberFormats = {
  'en-AU': {
    ...commonNumberFormats,
    currency: {
      ...commonNumberFormats.currency,
      currency: 'AUD',
    },
    currencyCompact: {
      ...commonNumberFormats.currencyCompact,
      currency: 'AUD',
    },
  } as NumberFormat,
  'en-CA': {
    ...commonNumberFormats,
    currency: {
      ...commonNumberFormats.currency,
      currency: 'CAD',
    },
    currencyCompact: {
      ...commonNumberFormats.currencyCompact,
      currency: 'CAD',
    },
  } as NumberFormat,
}

const format = {
  'en-AU': enAUFormat,
  'en-CA': enCAFormat,
}

const masks = {
  'en-AU': enAUMask,
  'en-CA': enCAMask,
}

const states = {
  'en-AU': enAUStates,
  'en-CA': enCAStates,
}

const i18n = new VueI18n({
  dateTimeFormats,
  numberFormats,
  locale: 'en-AU', // set locale
  fallbackLocale: 'en-AU', // set fallback locale
  messages, // set locale messages
  // If you need to specify other options, you can set other options
  // ...
})

function useLocalization() {
  const locale = computed(() => i18n.locale as Locale)
  const localeMasks = computed(() => masks[locale.value])
  const localeStates = computed(() => states[locale.value].States)

  function setLocale(locale: Locale) {
    i18n.locale = locale
  }

  function formatBusinessNumber(value: string | number | null | undefined) {
    if (value) {
      return format[locale.value].formatBusinessNumber(`${value}`)
    } else {
      return value
    }
  }

  function translate(
    key: Path<MessagesType>,
    values?: VueI18n.Values
  ): VueI18n.TranslateResult {
    return i18n.t(key, values)
  }

  return {
    locale,
    setLocale,
    translate,
    payeeBankAccount: payeeBankAccount[locale.value],
    bankAccount: bankAccount[locale.value],
    states: localeStates,
    getPlugin: function () {
      return i18n
    },
    getMessages: function () {
      return messages
    },
    getDateTimeFormats: function () {
      return dateTimeFormats
    },
    getDatePickerFormat: function (type: DateType) {
      return datePickerFormats[locale.value]?.[type]
    },
    getNumberFormats: function () {
      return numberFormats
    },
    getMask: function (key: keyof typeof localeMasks.value) {
      return localeMasks.value[key]
    },
    formatMoney: function (money: string | number | null | undefined): string {
      return i18n.n(Number(money ?? 0), 'currency')
    },
    formatMoneyCompact: function (
      money: string | number | null | undefined
    ): string {
      return i18n.n(Number(money ?? 0), 'currencyCompact')
    },
    formatNumber: function (money: string | number | null | undefined): string {
      return i18n.n(Number(money ?? 0), 'decimal')
    },
    formatNumberFixed: function (
      money: string | number | null | undefined
    ): string {
      return i18n.n(Number(money ?? 0), 'decimalFixed')
    },
    formatNumberFixedCompact: function (
      money: string | number | null | undefined
    ): string {
      return i18n.n(Number(money ?? 0), 'decimalFixedCompact')
    },
    formatBusinessNumber,
    localizeDate: function (date: Date, type: DateType) {
      return i18n.d(date, type)
    },
  }
}

export { useLocalization }
