import ui from '/~/core/ui'
import { Route } from 'vue-router'

// Scroll position saving
const SAVED_POSITIONS: { [key: string]: number } = {}

const getScrollContainer = (): HTMLElement | { scrollTop: number } => {
  return document.getElementById('app-main') || { scrollTop: 0 }
}

const getLayoutContainer = (): HTMLElement | { scrollTop: number } => {
  return document.getElementById('sidebar-content') || { scrollTop: 0 }
}

const getRouteKey = (route: Route): string => {
  return route.name || route.path.replace(/\//g, '_')
}

export const handleScrollPositionSaving = (from: Route): void => {
  const key = getRouteKey(from)

  if (ui.mobile) {
    SAVED_POSITIONS[key] = getScrollContainer().scrollTop
  } else {
    SAVED_POSITIONS[key] = getLayoutContainer().scrollTop
  }
}

export function scrollBehavior(
  to: Route,
  from: Route,
  savedPosition: { x: number; y: number } | null
): { x: number; y: number } | void {
  if (to.params.ignoreScrollBehavior) return

  const toKey = getRouteKey(to)

  if (to.params.preventTopScrolling || !savedPosition) {
    getScrollContainer().scrollTop = 0
    getLayoutContainer().scrollTop = 0
    return { x: 0, y: 0 }
  } else if (ui.mobile) {
    getScrollContainer().scrollTop = SAVED_POSITIONS[toKey] || 0
  } else {
    getLayoutContainer().scrollTop = SAVED_POSITIONS[toKey] || 0
    return savedPosition
  }
}
