import jwtDecode from 'jwt-decode'
import { JwtInMemoryStorage } from '/~/core/Jwt/JwtInMemoryStorage'

// jwt from session cookie
export class Jwt {
  token?: string
  data?: Jwt.Data
  jwtTokenStrategy?: null | IJwtStrategy
  protected storage: Jwt.Storage

  constructor() {
    this.storage = new JwtInMemoryStorage()
  }

  setJwtStrategy(jwtTokenStrategy: IJwtStrategy) {
    this.jwtTokenStrategy = jwtTokenStrategy
  }

  get expiry() {
    return this.getExpirationDate(this.token)
  }

  getExpirationDate(jwtToken?: string) {
    if (!jwtToken) {
      return null
    }

    const jwt = jwtDecode<Jwt.Data>(jwtToken)

    // multiplied by 1000 to convert seconds into milliseconds
    return (jwt?.exp && jwt.exp * 1000) || null
  }

  get version() {
    return this.data?.ver
  }

  get isThirdPartyUserToken() {
    return this.version === 'THIRD_PARTY_USER_ACCESS_CONTROL_V1.0'
  }

  set(token?: string) {
    this.token = token
    this.data = token ? jwtDecode(token) : undefined
  }

  async get() {
    if (!this.token) {
      this.token = this.storage.restore()
      this.set(this.token)
    }

    if (this.token) {
      return this.token
    }

    if (!this.jwtTokenStrategy) {
      throw new Error('JWT strategy is not set')
    }

    const token = await this.jwtTokenStrategy?.generateToken()

    this.set(token)
    this.storage.put(token)

    return token
  }

  clear() {
    this.set(undefined)
    this.storage.clear()
  }

  refetch() {
    this.clear()
    return this.get()
  }

  getStorage() {
    return this.storage
  }
}
