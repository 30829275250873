export interface IAnalyticsEvent {
  label?: string
  pageGroup?: string
  page?: string
  component1?: string
  component2?: string
  component3?: string
  cta?: string
}

export interface IEventParams {
  debounce?: boolean
}

type GetAnalyticsEvent = () => IAnalyticsEvent

export type AnalyticsEvent = IAnalyticsEvent | GetAnalyticsEvent

export abstract class BaseAnalyticsTool {
  abstract apiKey: string

  initiate(apiKey = ''): void {
    this.apiKey = apiKey
  }

  sendEvent(event: IAnalyticsEvent, params: IEventParams): void {
    console.warn(event, params)
  }
}
