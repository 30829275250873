import Vue, { computed, reactive, readonly } from 'vue'
import api from '/~/core/api'
import emitter from '/~/core/emitter'
import ui from '/~/core/ui'
import { ensureNumber } from '/~/utils/format/numeric'
import Address from '/~/composables/addresses/core/Address'

const OUT_OF_STOCK_ERROR_CODE = 410

const cart = reactive({
  loading: false,
  updating: false,
  count: 0,
  fees: { shipping: 0, processing: 0 },
  items: [],
  subTotal: 0,
  rebateTotal: 0,
  gifts: [],
})

const cartOrderAddresses = reactive<{
  loading: boolean
  billing: null | Address
  shipping: null | Address
}>({
  loading: false,
  billing: null,
  shipping: null,
})

const cartItemsFiltered = computed(() =>
  cart.items.filter((item) => item.type && item.type !== 'coupon')
)
const cartHasPhysical = computed(() =>
  cartItemsFiltered.value.some((item) => item.physical)
)
const cartHasEstore = computed(() =>
  cartItemsFiltered.value.some((item) => item.type === 'estore')
)
const cartHasDelivery = computed(() => {
  return cartHasPhysical.value || cartHasEstore.value
})

const cartQuantity = computed(() =>
  cartItemsFiltered.value.reduce((total, value) => {
    return total + value?.quantity ?? 0
  }, 0)
)
const cartItemsCount = computed(() => cartItemsFiltered.value.length)
const cartIsEmpty = computed(() => cartItemsFiltered.value.length === 0)
const isCartLoading = computed(() => cart.loading || cart.updating)

const cartSubTotalFiltered = computed(() =>
  cartItemsFiltered.value.reduce((prev, value) => {
    return prev + value.subTotal
  }, 0)
)
const cartShippingFee = computed(() => +(cart.fees?.shipping ?? 0))
const cartProcessingFee = computed(() => +(cart.fees?.processing ?? 0))

const cartTotal = computed(
  () => cartSubTotalFiltered.value + cartShippingFee.value
)

const reservedCartPoints = computed(() =>
  Object.keys(cart.items).reduce((acc, key) => {
    if (cart.items[key].product) {
      acc += cart.items[key].quantity * cart.items[key].points
    }
    return acc
  }, 0)
)

const cashbackRebateTotal = computed(() => ensureNumber(cart.rebateTotal))

const cashbackEnabled = computed(() => cashbackRebateTotal.value > 0)

const setCart = (data) => {
  cart.count = data.cart.count
  cart.fees = data.cart.fees
  cart.items = data.cart.items ?? []
  cart.subTotal = data.cart.subTotal
  cart.rebateTotal = data.cart.rebateTotal

  emitter.emit('cart:updated', cartTotal.value)
}

const clearCart = () => {
  cart.count = 0
  cart.fees = { shipping: 0, processing: 0 }
  cart.items = []
  cart.subTotal = 0
  cart.rebateTotal = 0
}

const fetchCart = async () => {
  try {
    cart.loading = true

    const { data } = await api.get('/v3/current-order/items')

    setCart(data)
  } catch (error) {
    console.error(error)
  } finally {
    cart.loading = false
  }
}

const addCartItem = async (item) => {
  try {
    cart.updating = true

    const { data } = await api.post('/v3/current-order/items', item, {
      notify: false,
    })

    setCart(data)

    return cart
  } catch (error) {
    console.error(error)

    Vue.notify({
      type: 'error',
      text: error.data.message,
      duration: 5000,
    })

    if (error.data.status === OUT_OF_STOCK_ERROR_CODE) {
      await fetchCart()
    }
  } finally {
    cart.updating = false
  }
}

const updateCartItem = async (payload) => {
  try {
    cart.updating = true

    const { data } = await api.put(`/v3/current-order/items/${payload.key}`, {
      quantity: payload.newState,
    })

    setCart(data)

    return data.message
  } catch (error) {
    console.error(error)

    if (error.data.status === OUT_OF_STOCK_ERROR_CODE) {
      await fetchCart()
    }

    return [error]
  } finally {
    cart.updating = false
  }
}

const removeCartItem = async (payload) => {
  try {
    cart.updating = true

    const item = cart.items.find((item) => payload.key === item.id)
    const { data } = await api.delete(`/v3/current-order/items/${payload.key}`)
    const filteredItems = cart.items.filter((item) => item.id !== payload.key)
    const lastPhysicalItem =
      cartOrderAddresses.shipping &&
      !!filteredItems.length &&
      !filteredItems.some((i) => i.physical)

    // Need to remove shipping address from order if last physical item is removed
    if (lastPhysicalItem) {
      await removeCartOrderAddress('shipping')
    }

    analyticsDeleteCartItem(item)
    setCart(data)

    return data.message
  } catch (error) {
    console.error(error)
    return [error]
  } finally {
    cart.updating = false
  }
}

const analyticsDeleteCartItem = (payload) => {
  const sbPrefix = 'SB - '

  if (window.lcAnalytics) {
    const productName = ''
    const product = {
      name: sbPrefix + payload.retailer.name + ' ' + productName,
      price: (payload.price * payload.quantity).toFixed(2),
      quantity: payload.quantity,
    }

    const deletedItem = [product]

    window.dataLayer.productRemoved = product

    window.lcAnalytics.track('productSet', deletedItem)

    if (window.webAnalytics) {
      window.webAnalytics.publish('event', 'removefromcart')
    }
  }
}

const fetchCartOrderAddresses = async () => {
  try {
    cartOrderAddresses.loading = true

    const { data } = await api.get('/v3/current-order/addresses')

    cartOrderAddresses.billing = data.billing ?? null
    cartOrderAddresses.shipping = data.shipping ?? null
  } catch (error) {
    console.error(error)
  } finally {
    cartOrderAddresses.loading = false
  }
}

const addCartOrderAddress = async (data) => {
  try {
    const { type, address } = data

    if (!address) {
      return
    }

    cartOrderAddresses.loading = true

    switch (type) {
      case 'billing':
      case 'shipping':
        cartOrderAddresses[type] = address
        break
      default:
        console.error(`Invalid address type: ${type}`)
        return
    }

    await api.post('/v3/current-order/addresses', {
      type,
      address: address.id,
    })
  } catch (error) {
    console.error(error)
    // return [error]
  } finally {
    cartOrderAddresses.loading = false
  }
}

const removeCartOrderAddress = async (type) => {
  try {
    cartOrderAddresses.loading = true

    switch (type) {
      case 'billing':
      case 'shipping':
        cartOrderAddresses[type] = null
        break
      default:
        console.error(`Invalid address type: ${type}`)
        return
    }

    await api.delete(`/v3/current-order/addresses/${type}`)
  } catch (error) {
    console.error(error)
    // return [error]
  } finally {
    cartOrderAddresses.loading = false
  }
}

function resetCardOrderAddresses() {
  cartOrderAddresses.billing = null
  cartOrderAddresses.shipping = null
}

function handleCartGoneItems() {
  if (ui.mobile) {
    emitter.emit('router:push', { hash: '#cart-home' })
  } else {
    emitter.emit('router:push', { path: '/', hash: '#cart-home' })
  }

  fetchCart()
}

emitter.on('checkout:cart-items-gone', handleCartGoneItems)

export const useCart = () => ({
  cart: readonly(cart),
  cartOrderAddresses: readonly(cartOrderAddresses),
  cartQuantity,
  cartItemsCount,
  cartIsEmpty,
  cartTotal,
  isCartLoading,
  cartItemsFiltered,
  cartHasPhysical,
  cartHasEstore,
  cartHasDelivery,
  cartSubTotalFiltered,
  cartShippingFee,
  cartProcessingFee,
  reservedCartPoints,
  clearCart,
  fetchCart,
  addCartItem,
  updateCartItem,
  removeCartItem,
  fetchCartOrderAddresses,
  addCartOrderAddress,
  removeCartOrderAddress,
  resetCardOrderAddresses,
  cashbackEnabled,
  cashbackRebateTotal,
})
