import Storage from '/~/utils/localStorage'

export class OtpLocalStorage implements Otp.Storage {
  protected triggerPoint

  constructor(triggerPoint: Otp.TriggerPoint) {
    this.triggerPoint = triggerPoint
  }

  protected getStorageKey(key: string) {
    return `otp:${this.triggerPoint}:${key}`
  }

  public get(key: string) {
    return Storage.get(this.getStorageKey(key))
  }

  public put(key: string, value: any) {
    Storage.set(this.getStorageKey(key), JSON.stringify(value))
  }

  public remove(key: string) {
    Storage.remove(this.getStorageKey(key))
  }

  public restore() {
    return this.get('lastOtp')
  }

  public clear() {
    this.remove('lastOtp')
  }
}
