const Storage = {
  set(key: string, value: string) {
    window?.localStorage?.setItem(key, value)
  },
  get<T>(key: string): T | undefined {
    const result = window?.localStorage?.getItem(key)

    try {
      return result ? JSON.parse(result) : undefined
    } catch (error) {
      console.error(error)
    }
  },
  remove(key: string) {
    window?.localStorage?.removeItem(key)
  },
}

export default Storage
